import { combineReducers } from 'redux'
import app from './app-reducer'
import user from './user-reducer'
import tour from './tour-reducer'
import lines from './lines-reducer'
import line from './line-reducer'
import regionPicker from './region-picker-reducer'
import storePicker from './store-picker-reducer'
import departmentPicker from './department-picker-reducer'
import linePicker from './line-picker-reducer'
import stores from './stores-reducer'
import displays from './displays-reducer'
import display from './display-reducer'
import summary from './summary-reducer'

const combinedReducers = combineReducers({
  app,
  user,
  lines,
  line,
  stores,
  displays,
  display,
  summary,
  regionPicker,
  storePicker,
  linePicker,
  departmentPicker,
  tour
})

export default combinedReducers
