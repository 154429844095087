import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Input, Row, Col } from 'antd'

import { Label } from './styled'

import { injectIntl } from 'react-intl'

import './index.css'

const TextSearch = props => {
    const { label, allowClear, disabled, enterButton, placeholder, value, onSearch } = props

    const { Search } = Input;

    return (
        <Row className="row">
            {label ?
                <Col   
                    xs={{ span: 4 }}
                    md={{ span: 3}}
                    lg={{ span: 2 }}
                    xl={{ span: 1 }}>
                    <Label>{label}</Label>
                </Col> : <></>
            }
            <Col
                xs={{ span: 20 }}
                md={{ span: 21 }}
                lg={{ span: 22 }}
                xl={{ span: 23 }}>
                <Search
                    style={{ width: 225 }}
                    placeholder={placeholder}
                    defaultValue={value}
                    allowClear={allowClear}
                    disabled={disabled}
                    onSearch={(value) => {
                        onSearch(value)
                    }}
                    enterButton={enterButton} />
            </Col>
        </Row>
    )
}
TextSearch.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    onSearch: PropTypes.func,
    allowClear: PropTypes.bool,
    disabled: PropTypes.bool,
    enterButton: PropTypes.bool,
}
TextSearch.defaultProps = {
    allowClear: true,
    disabled: false,
    enterButton: true,
}
const mapStateToProps = state => ({
    status: state.storePicker.status,
})
const mapDispatchToProps = {

}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TextSearch))