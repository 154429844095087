import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Select, Row, Col } from 'antd'

import { Label } from './styled'

import { injectIntl } from 'react-intl'

import {
    getRegionPickerItems,
    updateRegionPickerValue,
    resetRegionPickerItems
} from 'Redux/actions/region-picker-actions'

import './index.css'

const RegionPicker = props => {
    const { label, allowClear, disabled, region, onRegionChange, intl, items, status, getRegionPickerItems, resetRegionPickerItems } = props

    const { Option } = Select;

    useEffect(() => {
        if (items && items.length === 0 && status === 'idle') {
            getRegionPickerItems()
        }
        return () => {
            if ((status === 'fulfilled' || status === 'error') && items && items.length > 0) {
                resetRegionPickerItems() 
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, status])

    const options = items && items.map((item) => {
        return (
            <Option key={item.id}>{item.id} - {item.label}</Option>
        )
    })

    console.log(`region: ${region}`)
    return (
        <Row className="row">
            {label ?
                <Col   
                    xs={{ span: 4 }}
                    md={{ span: 3}}
                    lg={{ span: 2 }}
                    xl={{ span: 1 }}>
                    <Label>{label}</Label>
                </Col> : <></>
            }
            <Col
                xs={{ span: 20 }}
                md={{ span: 21 }}
                lg={{ span: 22 }}
                xl={{ span: 23 }}>
                <Select
                    style={{ width: 225 }}
                    value={region}
                    allowClear={allowClear}
                    disabled={disabled}
                    loading={status === 'loading'}
                    placeholder={intl.formatMessage({ id: 'region.picker.placeholder' })}
                    onChange={(value) => {
                        onRegionChange(value)
                    }}
                >
                    {options}
                </Select>
            </Col>
        </Row>
    )
}
RegionPicker.propTypes = {
    label: PropTypes.string,
    region: PropTypes.string,
    onRegionChange: PropTypes.func,
    allowClear: PropTypes.bool,
    disabled: PropTypes.bool
}
RegionPicker.defaultProps = {
    allowClear: true,
    disabled: false
}
const mapStateToProps = state => ({
    status: state.regionPicker.status,
    items: state.regionPicker.items
})
const mapDispatchToProps = {
    getRegionPickerItems,
    updateRegionPickerValue,
    resetRegionPickerItems
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegionPicker))