import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Select, Row, Col } from 'antd'

import { Label } from './styled'

import { injectIntl } from 'react-intl'

import {
    getLinePickerItems,
    updateLinePickerValue,
    resetLinePickerItems
} from 'Redux/actions/line-picker-actions'

import './index.css'

const LinePicker = props => {
    const { disabled, label, line, allowClear, year, department, onLineChange, intl, items, status, getLinePickerItems, resetLinePickerItems } = props

    const { Option } = Select;

    useEffect(() => {
        if (items && items.length === 0 && year) {
            if(status === 'idle') {
                getLinePickerItems(year, department)
            }
        }

        return () => {resetLinePickerItems()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year])

    const options = items && items.map((item) => {
        return (
            <Option key={item.id}>{item.name}</Option>
        )
    })

    return (
        <Row className="row">
            {label ?
                <Col   
                    xs={{ span: 4 }}
                    md={{ span: 3}}
                    lg={{ span: 2 }}
                    xl={{ span: 1 }}>
                    <Label>{label}</Label>
                </Col> : <></>
            }
            <Col
                xs={{ span: 20 }}
                md={{ span: 21 }}
                lg={{ span: 22 }}
                xl={{ span: 23 }}>
                <Select
                    disabled={disabled}
                    style={{ width: 225 }}
                    value={line}
                    allowClear={allowClear}
                    loading={status === 'loading'}
                    placeholder={intl.formatMessage({ id: 'line.picker.placeholder' })}
                    onChange={(value) => {
                        onLineChange(value)
                    }}>
                    {options}
                </Select>
            </Col>
        </Row>
    )
}
LinePicker.propTypes = {
    label: PropTypes.string,
    line: PropTypes.string,
    onLineChange: PropTypes.func,
    disabled: PropTypes.bool
}
LinePicker.defaultProps = {
    allowClear: true
}
const mapStateToProps = state => ({
    status: state.linePicker.status,
    items: state.linePicker.items    
})
const mapDispatchToProps = {
    getLinePickerItems,
    updateLinePickerValue,
    resetLinePickerItems
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LinePicker))