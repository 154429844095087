import { 
    GET_STORES_DATA_REQUEST, 
    GET_STORES_DATA_SUCCESS,
    GET_STORES_DATA_ERROR,
    GET_STORES_LIST_PAGE_REQUEST, 
    RESET_STORES_DATA,
    UPDATE_STORES_YEAR,
    UPDATE_STORES_LINE,
    UPDATE_STORES_REGION,
    UPDATE_STORES_NAME,
} from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getStoresPageByFilters = (year, regionId, storeName, lineId, pageNumber, pageSize) => async dispatch => {
    console.log(`getStoresPageByYear: year ${year}, regionId ${regionId}, lineId ${lineId}, pageNumber ${pageNumber}, pageSize ${pageSize}`)
    dispatch({ type: GET_STORES_DATA_REQUEST })
    
    try {        
        const res = await api.storesWithDisplay.getPageByFilters({ year, regionId, storeName, lineId, pageNumber, pageSize })
        setTimeout( ()=> {
            const storesList = res.data
            console.log(JSON.stringify(storesList, null, 2))
            return dispatch({ type: GET_STORES_DATA_SUCCESS, payload: storesList })
        }, 200)
    } catch (error) {
        dispatch({ type: GET_STORES_DATA_ERROR }) 
        return handleServerError(error)
    }
}

export const changeListPage = (year, regionId, storeName, lineId, pageNumber, pageSize) => async dispatch => {
    console.log(`changeListPage: year ${year}, regionId ${regionId}, lineId ${lineId}, pageNumber ${pageNumber}, pageSize ${pageSize}`)
    dispatch({ type: GET_STORES_LIST_PAGE_REQUEST })

    try {        
        const res = await api.storesWithDisplay.getPageByFilters({ year, regionId, storeName, lineId, pageNumber, pageSize })
        const storesList = res.data
        setTimeout( ()=> {
            return dispatch({ type: GET_STORES_DATA_SUCCESS, payload: storesList })
        }, 200)
    } catch (error) {
        dispatch({ type: GET_STORES_DATA_ERROR }) 
        return handleServerError(error)
    }
}

export const resetStoresList = () => async dispatch => {
    console.log(`resetStoresList`)
    return dispatch({ type: RESET_STORES_DATA })
}

export const updateStoresYearAndGetStoresPage = (year, regionId, storeName,pageNumber, pageSize) => async dispatch => { 
    console.log(`updateStoresYearAndGetStoresPageByYear: ${year}`)
    dispatch({ type: RESET_STORES_DATA })
    dispatch({ type: UPDATE_STORES_LINE, payload: undefined })
    dispatch({ type: UPDATE_STORES_YEAR, payload: year })
    dispatch(getStoresPageByFilters(year, regionId, storeName, undefined, pageNumber, pageSize))
}

export const updateStoresLineIdAndGetStoresPage = (year, regionId, storeName, lineId, pageNumber, pageSize) => async dispatch => { 
    console.log(`updateStoresLineIdAndGetStoresPage: ${lineId}`)
    dispatch({ type: RESET_STORES_DATA })
    dispatch({ type: UPDATE_STORES_LINE, payload: lineId })
    dispatch(getStoresPageByFilters(year, regionId, storeName, lineId, pageNumber, pageSize))
}

export const updateStoresRegionIdAndGetStoresPage = (year, regionId, storeName, lineId, pageNumber, pageSize) => async dispatch => { 
    console.log(`updateStoresRegionIdAndGetStoresPage: ${regionId}`)
    dispatch({ type: RESET_STORES_DATA })
    dispatch({ type: UPDATE_STORES_REGION, payload: regionId })
    dispatch(getStoresPageByFilters(year, regionId, storeName, lineId, pageNumber, pageSize))
}

export const updateStoresRegionNameAndGetStoresPage = (year, regionId, storeName, lineId, pageNumber, pageSize) => async dispatch => { 
    console.log(`updateStoresRegionNameAndGetStoresPage: ${storeName}`)
    dispatch({ type: RESET_STORES_DATA })
    dispatch({ type: UPDATE_STORES_NAME, payload: storeName })
    dispatch(getStoresPageByFilters(year, regionId, storeName, lineId, pageNumber, pageSize))
}