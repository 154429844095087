import { 
    GET_STORE_PICKER_DATA_REQUEST,
    GET_STORE_PICKER_DATA_SUCCESS,
    GET_STORE_PICKER_DATA_ERROR,
    UPDATE_STORE_PICKER_VALUE,
    RESET_STORE_PICKER_ITEMS
} from '../action-types'
import api from 'Common/api'

export const getStorePickerItems = () => async dispatch => {
    console.log(`getStorePickerItems`)
    dispatch({ type: GET_STORE_PICKER_DATA_REQUEST }) 
    
    try {
        const res = await api.stores.getAll()
        setTimeout( ()=> {
            const stores = res.data
            return dispatch({ type: GET_STORE_PICKER_DATA_SUCCESS, payload: stores })
        }, 200)
    } catch (error) {
        dispatch({ type: GET_STORE_PICKER_DATA_ERROR }) 
    }
}

export const updateStorePickerValue = (storeId) => async dispatch => { 
    console.log(`updateStorePickerValue: new store id ${storeId}`)
    return dispatch({ type: UPDATE_STORE_PICKER_VALUE, payload: storeId })
}

export const resetStorePickerItems = () => async dispatch => {
    console.log(`resetStorePickerItems`)
    return dispatch({ type: RESET_STORE_PICKER_ITEMS })
}