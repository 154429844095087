import { VALIDATE_TOKEN } from '../action-types'
import axiosConfig from 'Common/axiosConfig'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const validateToken = token => async dispatch => {
  // MOCK (start)
  // const mockUser = {
  //   name: 'Mike',
  //   surname: 'Wasowski',
  // }
  // return dispatch({ type: VALIDATE_TOKEN, payload: mockUser })
  // MOCK (end)
  try {
    const res = await api.user.authenticate(token)
    const user = res.data
    console.log(user);
    axiosConfig.defaults.headers.Authorization = `Bearer ${token}`
    return dispatch({ type: VALIDATE_TOKEN, payload: user })
  } catch (error) {
    return handleServerError(error)
  }
}
