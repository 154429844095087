import { 
    GET_DISPLAYS_DATA_REQUEST,
    GET_DISPLAYS_DATA_SUCCESS,
    GET_DISPLAYS_DATA_ERROR,
    GET_DISPLAYS_LIST_PAGE_REQUEST,
    RESET_DISPLAYS_DATA,
    UPDATE_DISPLAYS_YEAR,
    UPDATE_DISPLAYS_STORE,
    UPDATE_DISPLAYS_LINE,
    UPDATE_DISPLAYS_DEPARTMENT,
} from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getDisplaysPageByYearStoreDeptAndLineId = (year, storeId, departmentId, lineId, pageNumber, pageSize) => async dispatch => {
    
    dispatch({ type: GET_DISPLAYS_DATA_REQUEST }) 
    
    console.log(`store id ${storeId}, department id ${departmentId}, line id ${lineId}, year ${year}`)
    try { 
        const res = await api.displays.getPageByYearStoreDeptAndLineId({ year, storeId, departmentId, lineId, pageNumber, pageSize })
        setTimeout( ()=> {
            const displaysList = res.data
            return dispatch({ type: GET_DISPLAYS_DATA_SUCCESS, payload: { displaysList } })
        }, 200)
    } catch (error) {
        dispatch({ type: GET_DISPLAYS_DATA_ERROR }) 
        return handleServerError(error)
    }
}

export const changeDisplaysListPage = (year, storeId, departmentId, lineId, pageNumber, pageSize) => async dispatch => {
    console.log(`changeDisplaysListPage: year ${year}, storeId ${storeId}, department id ${departmentId}, lineId ${lineId}, pageNumber ${pageNumber}, pageSize ${pageSize}`)
    dispatch({ type: GET_DISPLAYS_LIST_PAGE_REQUEST })

    try {        
        const res = await api.displays.getPageByYearStoreDeptAndLineId({ year, storeId, departmentId, lineId, pageNumber, pageSize })
        const displaysList = res.data
        setTimeout( ()=> {
            return dispatch({ type: GET_DISPLAYS_DATA_SUCCESS, payload: { displaysList } })
        }, 200)
    } catch (error) {
        dispatch({ type: GET_DISPLAYS_DATA_ERROR }) 
        return handleServerError(error)
    }
}

export const resetDisplaysList = () => async dispatch => {
    console.log(`resetDisplaysList`)
    return dispatch({ type: RESET_DISPLAYS_DATA })
}

export const updateDisplayYearGetDisplaysPage = (year, storeId, departmentId, pageNumber, pageSize) => async dispatch => {
    dispatch({ type: RESET_DISPLAYS_DATA })
    dispatch({ type: UPDATE_DISPLAYS_YEAR, payload: year })
    dispatch({ type: UPDATE_DISPLAYS_LINE, payload: undefined })
    dispatch(getDisplaysPageByYearStoreDeptAndLineId(year, storeId, departmentId, undefined, pageNumber, pageSize))
}

export const updateDisplayStoreGetDisplaysPage = (year, storeId, departmentId, lineId, pageNumber, pageSize) => async dispatch => {
    dispatch({ type: RESET_DISPLAYS_DATA })
    dispatch({ type: UPDATE_DISPLAYS_STORE, payload: storeId })
    dispatch(getDisplaysPageByYearStoreDeptAndLineId(year, storeId, departmentId, lineId, pageNumber, pageSize))
}
export const updateDisplayFiltersAndGetDisplaysPage = (year, storeId, departmentId, lineId, pageNumber, pageSize) => async dispatch => { 
    console.log(`updateDisplayFiltersAndGetDisplaysPage: year ${year}, storeId ${storeId}, departmentId ${departmentId}, lineId ${lineId}`)
    dispatch({ type: RESET_DISPLAYS_DATA })
    dispatch({ type: UPDATE_DISPLAYS_YEAR, payload: year })
    dispatch({ type: UPDATE_DISPLAYS_STORE, payload: storeId })
    dispatch({ type: UPDATE_DISPLAYS_DEPARTMENT, payload: departmentId })
    dispatch({ type: UPDATE_DISPLAYS_LINE, payload: lineId })
    dispatch(getDisplaysPageByYearStoreDeptAndLineId(year, storeId, departmentId, lineId, pageNumber, pageSize))
}