import { 
    GET_SUMMARY_DATA_REQUEST, 
    GET_SUMMARY_DATA_SUCCESS, 
    GET_SUMMARY_DATA_ERROR,
    GET_SUMMARY_PAGE_REQUEST,
    RESET_SUMMARY_DATA,
    UPDATE_SUMMARY_YEAR,
} from '../action-types'

const initialState = {
    year: new Date().getFullYear().toString(),
    currentPage: 0,
    pageSize: 5,
    totalItems: 0,
    status: 'fulfilled',
    lines: [],
    stores: [],
    displays: []
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_SUMMARY_DATA_REQUEST:
            return {...state, status: 'pageLoading' }
        case GET_SUMMARY_DATA_SUCCESS:
            
            const updatedLines = state.lines.splice()
            for (const item of payload.linesList.results) {
                let isNew = true;

                for (let i = 0; i < updatedLines.length; i += 1) {
                    if (updatedLines[i].id === item.id) {
                        updatedLines[i] = { ...item };
                        isNew = false;
                    }
                }

                if (isNew) {
                    updatedLines.push({ ...item });
                }
            }
            const sortedStores = payload.storesList.results.sort((a, b)=> {
                const aRegionInt = parseInt(a.regionId)
                const bRegionInt = parseInt(b.regionId)
                const aStoreInt = parseInt(a.storeId)
                const bStoreInt = parseInt(b.storeId)
                return (
                    (isNaN(aRegionInt) || isNaN(bRegionInt) ? a.regionId.localeCompare(b.regionId) :  aRegionInt - bRegionInt) ||
                    (isNaN(aStoreInt) || isNaN(bStoreInt) ? a.storeId.localeCompare(b.storeId) :  aStoreInt - bStoreInt)
                )
            }) 

            return {
                ...state,
                currentPage: payload.linesList.pagingData.currentPage,
                pageSize: payload.linesList.pagingData.pageSize,
                totalItems: payload.linesList.pagingData.totalItems,
                status: 'fulfilled',
                lines: updatedLines,
                stores: sortedStores,
                displays: payload.displaysList.results,
            }
        case GET_SUMMARY_DATA_ERROR:
            return { ...state, status: 'error' }
        case GET_SUMMARY_PAGE_REQUEST:
            return { ...state, status: 'listLoading' }
        case RESET_SUMMARY_DATA:
            return { ...state, lines: [], stores: [], displays: [] }
        case UPDATE_SUMMARY_YEAR:
            return { ...state, year: payload }
        default:
            return state
    }
}