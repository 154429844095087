import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { FormattedMessage, injectIntl } from 'react-intl'
import Loader from 'Components/Loader'
import YearPicker from 'Components/YearPicker'
import StorePicker from 'Components/StorePicker'
import DepartmentPicker from 'Components/DepartmentPicker'
import TextSearch from 'Components/TextSearch'
import { connect } from 'react-redux'

import { 
  getLinesPageByYear, 
  changeListPage,
  updateLinesYearAndGetLinesPageByYear,
  updateLinesStoreAndGetLinesPageByYear,
  updateLinesDepartmentAndGetLinesPageByYear,
  updateLinesNameAndGetLinesPageByYear,
  resetLinesList
} from 'Redux/actions/lines-actions'

import { isUserAdmin, isUserCP, isUserMerchandise } from 'Redux/selectors/user-selector'
import { PageTitle, UppercaseButton, Label } from 'Common/styled'
import { List, PageHeader } from 'antd'

import './index.css'
class Lines extends Component {
  static propTypes = {
    store: PropTypes.string,
    year: PropTypes.string,
    status: PropTypes.string,
    user: PropTypes.object,
    isUserAdmin: PropTypes.bool,
    isUserCP: PropTypes.bool,
    isUserMerchandise: PropTypes.bool,
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    totalItems: PropTypes.number,
    lines: PropTypes.arrayOf(PropTypes.object),
    getLinesPageByYear: PropTypes.func,
    changeListPage: PropTypes.func,
    updateLinesYearAndGetLinesPageByYear: PropTypes.func,
    updateLinesStoreAndGetLinesPageByYear: PropTypes.func,
    updateLinesDepartmentAndGetLinesPageByYear: PropTypes.func,
    updateLinesNameAndGetLinesPageByYear: PropTypes.func,
    resetLinesList: PropTypes.func,
  }

  componentDidMount() {
    const { user, isUserAdmin, isUserCP, isUserMerchandise, store } = this.props
    
    // console.log('isUserAdmin:' + isUserAdmin);
    // console.log('store:' + store)
    // console.log('isUserCP:' + isUserCP)
    // console.log('isUserMerchandise:' + isUserMerchandise)
    if (!isUserAdmin && !store && !isUserCP && !isUserMerchandise ) {
      // console.log('Updating Store with: ' + user.storeId);
      this.updateStoreAndGetProductLineList(user.storeId);
    } else {
      this.getProductLineList(0)
    }
  }

  componentWillUnmount() {
    const { resetLinesList } = this.props
    resetLinesList()
  }

  getProductLineList(page) {
    const { getLinesPageByYear, pageSize, year, store, department, name } = this.props
    getLinesPageByYear(year, store, department, name, page, pageSize)
  }

  changeLinePage(page) {
    const { changeListPage, pageSize, year, store, department, name } = this.props
    changeListPage(year, store, department, name, page, pageSize)
  }

  updateYearAndGetProductLineList(year) {
    const { updateLinesYearAndGetLinesPageByYear, pageSize, store, department, name } = this.props
    updateLinesYearAndGetLinesPageByYear(year, store, department, name, 0, pageSize)
  }

  updateStoreAndGetProductLineList(store) {
    const { updateLinesStoreAndGetLinesPageByYear, pageSize, year, department, name } = this.props
    updateLinesStoreAndGetLinesPageByYear(year, store, department, name, 0, pageSize)
  }

  updateDepartmentAndGetProductLineList(department) {
    const { updateLinesDepartmentAndGetLinesPageByYear, pageSize, store, year, name } = this.props
    updateLinesDepartmentAndGetLinesPageByYear(year, store, department, name, 0, pageSize)
  }

  updateNameAndGetProductLineList(name) {
    const { updateLinesNameAndGetLinesPageByYear, pageSize, year, store, department } = this.props
    updateLinesNameAndGetLinesPageByYear(year, store, department, name, 0, pageSize)
  }

  nextPath(path) {
    const { history } = this.props
    history.push(path)
  }

  render() {
    const { lines, pageSize, currentPage, totalItems, isUserAdmin, year, store, department, name, status, intl } = this.props

    let content
    if (status === 'idle' || status === 'pageLoading')  {
      content = <Loader />
    } else {
      if (isEmpty(lines) && status === 'fulfilled') {
        content =
          <PageTitle>
            <FormattedMessage id={'lines.no.results'} />
          </PageTitle>
      } else {        
        content =
          <List
            itemLayout="vertical"
            loading={status === 'listLoading'}
            bordered
            pagination={{
              onChange: page => {
                console.log(`Retrieving page ${page-1}`);
                this.changeLinePage(page-1)
              },
              pageSize: pageSize,
              current: currentPage+1,
              total: totalItems,
              responsive: true,
              simple: true,
            }}
            dataSource={lines}
            className={isUserAdmin ? 'listStyle' : ''}
            renderItem={item => (
              <List.Item
                key={item.id}
                actions={[
                  <Link 
                    to={`/displays?year=${year}&lineId=${item.id}&departmentId=${item.departmentId || ''}&storeId=${store || ''}&emptyStore=true`}>
                    <FormattedMessage id={'lines.line.stores'} />
                    {item.storesNumber}
                  </Link>
                  ,
                ]}
              >
                <List.Item.Meta
                  title={<Link className={!isUserAdmin ? 'disabled-link' : ''} to={`/line/${item.id}`}>{item.name}</Link>}
                  description={
                    <div>
                      <div>
                        <b><FormattedMessage id={'lines.line.department'} /></b>
                        {item.department}
                      </div>
                      <div>
                        <b><FormattedMessage id={'lines.line.kind'} /></b>
                        {item.kind}
                      </div>
                      <div>
                        <b><FormattedMessage id={'lines.line.availability.date'} /></b>
                        {item.storeAvailability.toString()}
                      </div>
                    </div>
                  }
                />
                {item.content}
              </List.Item>
            )}
          />
      }
    }

    return (
      <section>
        <PageHeader 
          title={intl.formatMessage({ id: 'home.line.button' })} />
        <div className="filterContainer">
          <div className="filterRow">
            <Label><FormattedMessage id={'filter.by'}/></Label>
          </div>
          <div className="filterRow">
            <YearPicker
              label='year'
              date={year}
              onDateChange={(date) => {
                console.log(`onDateChange date: ${date}`)
                this.updateYearAndGetProductLineList(date.year().toString())
              }}
            />
          </div>
          <div className="filterRow">
            <StorePicker
              label={intl.formatMessage({id: 'store'})}
              store={store}
              onStoreChange={(store) => {
                console.log(`onStoreChange store: ${store}`)
                this.updateStoreAndGetProductLineList(store)
              }}
            />
          </div>
          <div className="filterRow">
            <DepartmentPicker
              label={intl.formatMessage({id: 'dept'})}
              department={department}
              onDepartmentChange={(department) => {
                console.log(`onDepartmentChange department: ${department}`)
                this.updateDepartmentAndGetProductLineList(department)
              }}
            />
          </div>
          <div className="filterRow">
            <TextSearch
              label={intl.formatMessage({id: 'name'})}
              placeholder={intl.formatMessage({id: 'line.name.filter.placeholder'})}
              value={name}
              onSearch={(value) => {
                this.updateNameAndGetProductLineList(value)
              }}
            />
          </div>
        </div>
        <div className="contentContainer">
          {content}
        </div>
        <div className="buttonContainer" hidden={!isUserAdmin}>
          <UppercaseButton onClick={() => { this.nextPath('/newLine') }}>
            <FormattedMessage id={'lines.new.line'} />
          </UppercaseButton>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  store: state.lines.store,
  department: state.lines.department,
  name: state.lines.name,
  year: state.lines.year,
  status: state.lines.status,
  user: state.user,
  isUserAdmin: isUserAdmin(state),
  isUserCP: isUserCP(state),
  isUserMerchandise: isUserMerchandise(state),
  currentPage: state.lines.currentPage,
  pageSize: state.lines.pageSize,
  totalItems: state.lines.totalItems,
  lines: state.lines.items
})
const mapDispatchToProps = {
  getLinesPageByYear,
  changeListPage,
  updateLinesYearAndGetLinesPageByYear,
  updateLinesStoreAndGetLinesPageByYear,
  updateLinesDepartmentAndGetLinesPageByYear,
  updateLinesNameAndGetLinesPageByYear,
  resetLinesList,
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Lines))
