import { 
    GET_DEPARTMENT_PICKER_DATA_REQUEST,
    GET_DEPARTMENT_PICKER_DATA_SUCCESS,
    GET_DEPARTMENT_PICKER_DATA_ERROR,
    UPDATE_DEPARTMENT_PICKER_VALUE,
    RESET_DEPARTMENT_PICKER_ITEMS
} from '../action-types'
import api from 'Common/api'

export const getDepartmentPickerItems = () => async dispatch => {
    console.log(`getDepartmentPickerItems`)
    dispatch({ type: GET_DEPARTMENT_PICKER_DATA_REQUEST }) 
    
    try {
        const res = await api.departments.getAll()
        setTimeout( ()=> {
            const stores = res.data
            return dispatch({ type: GET_DEPARTMENT_PICKER_DATA_SUCCESS, payload: stores })
        }, 200)
    } catch (error) {
        dispatch({ type: GET_DEPARTMENT_PICKER_DATA_ERROR }) 
    }
}

export const updateDepartmentPickerValue = (departmentId) => async dispatch => { 
    console.log(`updateDepartmentPickerValue: new department id ${departmentId}`)
    return dispatch({ type: UPDATE_DEPARTMENT_PICKER_VALUE, payload: departmentId })
}

export const resetDepartmentPickerItems = () => async dispatch => {
    console.log(`resetDepartmentPickerItems`)
    return dispatch({ type: RESET_DEPARTMENT_PICKER_ITEMS })
}