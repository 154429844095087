import { 
    LINE_REQUEST,
    LINE_ERROR,
    SAVE_NEW_LINE,
    UPDATE_LINE,
    GET_LINE_DATA,
    RESET_LINE_DATA,
    UPDATE_LOCAL_LINE_DATA
} from '../action-types'

const initialState = {
    status: 'idle',
    item: {
        year: new Date().getFullYear().toString(),
        storeAvailability: null,
    }
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case LINE_REQUEST:
            return { ...state, status: 'loading' }
        case SAVE_NEW_LINE:
            return { ...state, status: 'saved' }
        case GET_LINE_DATA:
            return { ...state, status: 'fulfilled', item: payload }
        case UPDATE_LOCAL_LINE_DATA:
                return { ...state, item: payload }
        case UPDATE_LINE:
            return { ...state, status: 'saved', item: payload }
        case LINE_ERROR:
            return { ...state, status: 'error' }
        case RESET_LINE_DATA:
            return { ...state, ...initialState }
        default:
            return state
    }
}