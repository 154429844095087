import { 
    GET_LINE_PICKER_DATA_REQUEST,
    GET_LINE_PICKER_DATA_SUCCESS,
    GET_LINE_PICKER_DATA_ERROR,
    UPDATE_LINE_PICKER_VALUE,
    UPDATE_LINE_PICKER_YEAR,
    UPDATE_LINE_PICKER_DEPARTMENT,
    RESET_LINE_PICKER_ITEMS
} from '../action-types'
import api from 'Common/api'

export const getLinePickerItems = (year, department) => async dispatch => {
    console.log(`getLinePickerItems`)
    dispatch({ type: GET_LINE_PICKER_DATA_REQUEST }) 
    
    try {
        const res = await api.lines.getPickerItemsByYear({year, department})
        setTimeout( ()=> {
            const lines = res.data
            return dispatch({ type: GET_LINE_PICKER_DATA_SUCCESS, payload: lines })
        }, 200)
    } catch (error) {
        dispatch({ type: GET_LINE_PICKER_DATA_ERROR }) 
    }
}

export const updateLinePickerValue = (lineId) => async dispatch => { 
    console.log(`updateLinePickerValue: new line id ${lineId}`)
    return dispatch({ type: UPDATE_LINE_PICKER_VALUE, payload: lineId })
}

export const updateLinePickerYear = (year, department) => async dispatch => {
    console.log(`updateLinePickerYear: year ${year}`)
    dispatch({ type: UPDATE_LINE_PICKER_YEAR, payload: year })
    dispatch(getLinePickerItems(year, department)) 
}

export const updateLinePickerDepartment = (year, department) => async dispatch => {
    console.log(`updateLinePickerDepartment: department ${department}`)
    dispatch({ type: UPDATE_LINE_PICKER_DEPARTMENT, payload: department })
    dispatch(getLinePickerItems(year, department)) 
}

export const resetLinePickerItems = () => async dispatch => {
    console.log(`resetLinePickerItems`)
    return dispatch({ type: RESET_LINE_PICKER_ITEMS })
}