import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { setTourData, resetTourData } from 'Redux/actions/tour-actions'

import { TOUR_HOME, tourHomeSteps } from 'Common/constants'

import { UppercaseButton, PageTitle } from 'Common/styled'
import './index.css'

class Home extends Component {
  static propTypes = {
    setTourData: PropTypes.func,
    resetTourData: PropTypes.func,
  }
  componentDidMount() {
    const { setTourData } = this.props
    setTourData(TOUR_HOME, tourHomeSteps)
  }
  componentWillUnmount() {
    const { resetTourData } = this.props
    resetTourData()
  }

  nextPath(path) {
    const { history } = this.props
    history.push(path)
  }

  render() {
    return (
      <>
        <PageTitle>
          <FormattedMessage id={'home.title'} />
        </PageTitle>
        <ul id="buttonList">
        <li>
          {
            <UppercaseButton className="homeButton" onClick={() => {this.nextPath('/lines')}}>
              <FormattedMessage id={'home.line.button'} />
            </UppercaseButton>
          }
        </li>
        <li>
          {
            <UppercaseButton className="homeButton" onClick={() => {this.nextPath('/stores')}}>
              <FormattedMessage id={'home.store.button'} />
            </UppercaseButton>
          }
        </li>
        <li>
          {
            <UppercaseButton className="homeButton" onClick={() => {this.nextPath('/displays')}}>
              <FormattedMessage id={'home.display.button'} />
            </UppercaseButton>
          }
        </li>
        <li>
          {
            <UppercaseButton className="homeButton" onClick={()=> window.open('https://datastudio.google.com/reporting/8ef40486-22b4-480f-aa99-7b6cbee44e4d', "_blank")}>
              <FormattedMessage id={'home.summary.button'} />
            </UppercaseButton>
          }
        </li>
      </ul>
      </>
    )
  }
}

const mapStateToProps = state => ({})
const mapDispatchToProps = {
  setTourData,
  resetTourData,
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
