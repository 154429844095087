import { 
    GET_LINES_DATA_REQUEST, 
    GET_LINES_DATA_SUCCESS, 
    GET_LINES_DATA_ERROR,
    GET_LINES_LIST_PAGE_REQUEST,
    RESET_LINES_DATA,
    UPDATE_LINES_YEAR,
    UPDATE_LINES_STORE,
    UPDATE_LINES_DEPARTMENT,
    UPDATE_LINES_NAME
} from '../action-types'

const initialState = {
    store: undefined,
    department: undefined,
    name: undefined,
    year: new Date().getFullYear().toString(),
    currentPage: 0,
    pageSize: 5,
    totalItems: 0,
    status: 'idle',
    items: []
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_LINES_DATA_REQUEST:
            return {...state, status: 'pageLoading' }
        case GET_LINES_DATA_SUCCESS:
            const updatedLines = state.items.splice()
            for (const item of payload.results) {
                let isNew = true;

                for (let i = 0; i < updatedLines.length; i += 1) {
                    if (updatedLines[i].id === item.id) {
                        updatedLines[i] = { ...item };
                        isNew = false;
                    }
                }

                if (isNew) {
                    updatedLines.push({ ...item });
                }
            }

            return {
                ...state,
                currentPage: payload.pagingData.currentPage,
                pageSize: payload.pagingData.pageSize,
                totalItems: payload.pagingData.totalItems,
                status: 'fulfilled',
                items: updatedLines
            }
        case GET_LINES_DATA_ERROR:
            return { ...state, status: 'error' }
        case GET_LINES_LIST_PAGE_REQUEST:
            return { ...state, status: 'listLoading' }
        case RESET_LINES_DATA:
            return { ...state, items: [] }
        case UPDATE_LINES_YEAR:
            return { ...state, year: payload }
        case UPDATE_LINES_STORE:
            return { ...state, store: payload }
        case UPDATE_LINES_DEPARTMENT:
            return { ...state, department: payload }
        case UPDATE_LINES_NAME:
            return { ...state, name: payload }
        default:
            return state
    }
}