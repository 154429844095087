import { 
    GET_DISPLAYS_DATA_REQUEST,
    GET_DISPLAYS_DATA_SUCCESS,
    GET_DISPLAYS_DATA_ERROR,
    GET_DISPLAYS_LIST_PAGE_REQUEST,
    RESET_DISPLAYS_DATA,
    UPDATE_DISPLAYS_YEAR,
    UPDATE_DISPLAYS_STORE,
    UPDATE_DISPLAYS_LINE,
    UPDATE_DISPLAYS_DEPARTMENT,
} from '../action-types'

const initialState = {
    currentPage: 0,
    pageSize: 5,
    totalItems: 0,
    status: 'idle',
    year: new Date().getFullYear().toString(),
    storeId: undefined,
    departmentId: undefined,
    lineId: undefined,
    items: []
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_DISPLAYS_DATA_REQUEST:
            return {...state, status: 'pageLoading' }
        case GET_DISPLAYS_DATA_SUCCESS:
            const updatedDisplays = state.items.splice()
            for (const item of payload.displaysList.results) {
                let isNew = true;

                for (let i = 0; i < updatedDisplays.length; i += 1) {
                    if (updatedDisplays[i].id === item.id) {
                        updatedDisplays[i] = { ...item };
                        isNew = false;
                    }
                }

                if (isNew) {
                    updatedDisplays.push({ ...item });
                }
            }

            return {
                ...state, 
                currentPage: payload.displaysList.pagingData.currentPage,
                pageSize: payload.displaysList.pagingData.pageSize,
                totalItems: payload.displaysList.pagingData.totalItems,
                status: 'fulfilled',
                items: updatedDisplays
            }
        case GET_DISPLAYS_DATA_ERROR:
            return { ...state, status: 'error' }
        case GET_DISPLAYS_LIST_PAGE_REQUEST:
            return { ...state, status: 'listLoading' }
        case RESET_DISPLAYS_DATA:
            return { ...state, items: [] }
        case UPDATE_DISPLAYS_YEAR:
            return { ...state, year: payload, lineId: undefined}
        case UPDATE_DISPLAYS_STORE:
            return { ...state, storeId: payload}
        case UPDATE_DISPLAYS_LINE:
            return { ...state, lineId: payload}
        case UPDATE_DISPLAYS_DEPARTMENT:
            return { ...state, departmentId: payload}
        default:
            return state
    }
}