import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { FormattedMessage, injectIntl } from 'react-intl'
import Loader from 'Components/Loader'
import StorePicker from 'Components/StorePicker'
import LinePicker from 'Components/LinePicker'
import PicturesWall from 'Components/PicturesWall'
import { getMomentDate, getMomentYearDate } from 'Common/utils'
import { connect } from 'react-redux'

import { saveNewDisplay, updateDisplay, getDisplay, resetDisplay, updateLocalDisplayData } from 'Redux/actions/display-actions'
import { updateLinePickerYear } from 'Redux/actions/line-picker-actions'

import { isUserAdmin, isUserCP, isUserMerchandise, isUserStoreAdmin } from 'Redux/selectors/user-selector'

import { PageTitle, UppercaseButton } from 'Common/styled'
import {
  Form,
  DatePicker,
  InputNumber,
  Switch,
  Input,
} from 'antd';

import config from 'Common/config'

import './index.css'
class Display extends Component {
  constructor(props) {
    super(props)

    this.formRef = React.createRef();

    this.state = {
      id: null,
      isYearPickerOpen: false,
    }
  }

  static propTypes = {
    status: PropTypes.string,
    errorCode: PropTypes.number,
    isUserAdmin: PropTypes.bool,
    isUserStoreAdmin: PropTypes.bool,
    isUserCP: PropTypes.bool,
    isUserMerchandise: PropTypes.bool,
    display: PropTypes.object,
    saveNewDisplay: PropTypes.func,
    updateDisplay: PropTypes.func,
    getDisplay: PropTypes.func,
    updateLinePickerYear: PropTypes.func,
    updateLocalDisplayData: PropTypes.func,
    resetDisplay: PropTypes.func,
  }

  componentDidMount() {
    const { updateLinePickerYear, display} = this.props;

    const { displayId } = this.props.match.params
    if (displayId) {
      this.getProductDisplay(displayId)
    } else {
      updateLinePickerYear(display.year)
    }
  }

  componentDidUpdate() {
    const { status, history } = this.props
    if (status === 'saved') {
      history.push('/displays')
    }

    if (status === 'fulfilled') {
      const { updateLinePickerYear, display } = this.props
      updateLinePickerYear(display.year)
    }
  }

  componentWillUnmount() {
    const { resetDisplay } = this.props
    resetDisplay()
  }

  saveNewProductDisplay(newLine) {
    const { saveNewDisplay } = this.props
    saveNewDisplay(newLine)
  }

  updateProductDisplay(displayId, displayToUpdate) {
    const { updateDisplay } = this.props
    console.log(`updateProductDisplay id ${displayId}:`, displayToUpdate)
    updateDisplay(displayId, displayToUpdate)
  }

  getProductDisplay(displayId) {
    const { getDisplay } = this.props
    console.log("Here!");
    getDisplay(displayId).then(() => {
      this.setState({
        id: displayId
      })
    })
  }

  updateDisplayYear(year) {
    const { updateLocalDisplayData, display, updateLinePickerYear } = this.props

    this.props.form.setFieldsValue({
      lineId: undefined
    });

    const updatedDisplay = {
      ...display,
      year: year,
      lineId: undefined,
    }

    updateLinePickerYear(year)
    updateLocalDisplayData(updatedDisplay)
  }

  updateDisplayStore(store) {
    const { updateLocalDisplayData, display } = this.props
    
    this.props.form.setFieldsValue({
      storeId: store,
    });

    const updatedDisplay = {
      ...display,
      storeId: store
    }

    updateLocalDisplayData(updatedDisplay)
  }

  updateDisplayLine(line) {
    const { updateLocalDisplayData, display } = this.props
    
    this.props.form.setFieldsValue({
      lineId: line,
    });

    const updatedDisplay = {
      ...display,
      lineId: line
    }

    updateLocalDisplayData(updatedDisplay)
  }

  onPicturesChange(fileList) {
    this.props.form.setFieldsValue({
      pictures: fileList
      });
  }

  nextPath(path) {
    const { history } = this.props
    history.push(path)
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { id } = this.state
        const { updateLocalDisplayData } = this.props

        console.log('values:',values)

        
        const display = {
          year: values.year.format('YYYY'),
          storeId: values.storeId,
          lineId: values.lineId,
          date: values.date.format('YYYY-MM-DD'),
          pilot: values.pilot,
          pictures: values.pictures,
          linearMeters: values.linearMeters,
          cpFeedback: values.cpFeedback,
          merchandiseFeedback: values.merchandiseFeedback
        }

        updateLocalDisplayData(display)
        console.log("Saving display... " + id);
        if (id) {
          this.updateProductDisplay(id, { ...display, id: parseFloat(id) })
          return
        }
        this.saveNewProductDisplay(display)
      }
    });
  };

  render() {
    const { isYearPickerOpen } = this.state
    const { status, errorCode, display, isUserAdmin, isUserStoreAdmin, isUserCP, isUserMerchandise, intl, user } = this.props

    let content
    
    if (status === 'loading') {
      content = <Loader />
    } else if (status === 'error' && errorCode === 404) {
      content =
        <PageTitle>
          <FormattedMessage id={'display.not.found'} />
        </PageTitle>
    } else if (isUserStoreAdmin && display && display.storeId && display.storeId !== user.storeId) {
      content = 
        <PageTitle>
          <FormattedMessage id={'display.store.no.access'} />
        </PageTitle>
    } else {
      const { getFieldDecorator } = this.props.form
      
      let initialStore = isUserStoreAdmin ? user.storeId : undefined
      
      let fileList = []
      
      fileList = display.pictures.map((picture) => {
        const pictureFile = {
          uid: picture.uuid,
          name: `${picture.uuid}.png`,
          status: 'done',
          url: config.API_URL + `/productDisplayImage/${picture.uuid}`,
        }
        return pictureFile
      })

      
      content =
        <Form
          ref={this.formRef}
          layout="vertical"
          size="default"
          onSubmit={this.handleSubmit}
        >
          <Form.Item label={intl.formatMessage({ id: 'year' })}>
            {getFieldDecorator('year', {
              initialValue: getMomentYearDate(display.year),
              rules: [
                {
                  type: 'object',
                  required: true,
                  message: intl.formatMessage({ id: 'line.year.empty' })
                },
              ],
            })(
              <DatePicker
                open={isYearPickerOpen}
                onFocus={() => {
                  this.setState({ isYearPickerOpen: true })
                }}
                onOpenChange={status => {
                  this.setState({ isYearPickerOpen: status })
                }}
                onPanelChange={(date) => {
                  console.log(`onChangeDate:  ${date}`)
                  this.props.form.setFieldsValue({
                    year: date,
                  })
                  this.updateDisplayYear(date.year().toString())
                  this.setState({ isYearPickerOpen: false })
                }}
                format="YYYY"
                mode="year"
                allowClear={false}
                placeholder={intl.formatMessage({ id: 'year.picker.placeholder' })}
                disabled={!isUserAdmin  && !isUserStoreAdmin}
              />
            )
            }
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'store' })}>
            {getFieldDecorator('storeId', {
              initialValue: !isEmpty(display) && !display.storeId ? initialStore : display.storeId,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'display.store.empty' })
                },
              ],
            })(
              <StorePicker
                allowClear={false}
                disabled={!isUserAdmin}
                store={!isEmpty(display) && !display.storeId ? initialStore : display.storeId}
                onStoreChange={(store) => {
                  console.log(`onStoreChange store: ${store}`)
                  this.updateDisplayStore(store)
                }}
              />
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'line' })}>
            {getFieldDecorator('lineId', {
              initialValue: !isEmpty(display) && !display.lineId ? undefined : display.lineId,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'display.line.empty' })
                },
              ],
            })(
              <LinePicker
                disabled={!isUserAdmin && !isUserStoreAdmin}
                year={display.year}
                line={display.lineId ? `${display.lineId}` : undefined}
                allowClear={false}                
                onLineChange={(line) => {
                  console.log(`on Line changed: ${line}`)
                  this.updateDisplayLine(line)
                }}
              />
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'display.date' })}>
            {getFieldDecorator('date', {
              initialValue: display.date ? getMomentDate(display.date) : null,
              rules: [
                {
                  type: 'object',
                  required: true,
                  message: intl.formatMessage({ id: 'display.date.empty' })
                },
              ],
            })(<DatePicker                   
                  disabled={!isUserAdmin && !isUserStoreAdmin}                
                  allowClear={false} 
                  />)}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'pilot' })}>
            {getFieldDecorator('pilot', { 
              valuePropName: 'checked',
              initialValue: display.pilot,
            })(<Switch 
                  disabled={!isUserAdmin && !isUserStoreAdmin}
                />)}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'linear.meters' })}>
            {getFieldDecorator('linearMeters', { 
              initialValue: display.linearMeters ? display.linearMeters : 0,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'display.linear.meters.empty' })
                },
              ],                
            })(<InputNumber 
                  precision={0} 
                  min={0} 
                  disabled={!isUserAdmin && !isUserStoreAdmin}
                  />)}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'display.pictures' })}>
            {getFieldDecorator('pictures', {
              initialValue: fileList,
              rules: [
                {
                  type: 'array',
                  required: true,
                  min: 1,
                  max: 5,
                  message: intl.formatMessage({ id: 'display.display.pictures.empty' })
                },
              ],
              validator: {

              }
            })(
              status !== 'saved' ?
                <PicturesWall 
                  disabled={!isUserAdmin && !isUserStoreAdmin}
                  onPicturesChange={(fileList) => {
                    this.onPicturesChange(fileList)
                  }}
                  onRemove={(file) => {
                    return false;
                  }}
                  fileList={fileList}
                />
                :
                <></>
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'cp.feedback' })}>
            {getFieldDecorator('cpFeedback', {
              initialValue: isEmpty(display) ? '' : display.cpFeedback,
              rules: [
                {
                  required: false,
                  whitespace: true,
                },
              ],
            })(<Input.TextArea 
                
                autoSize={true}
                disabled={!isUserAdmin && !isUserCP}
              />)}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'merchandise.feedback' })}>
            {getFieldDecorator('merchandiseFeedback', {
              initialValue: isEmpty(display) ? '' : display.merchandiseFeedback,
              rules: [
                {
                  required: false,
                  whitespace: true,
                },
              ],
            })(<Input.TextArea 
                autoSize={true}
                disabled={!isUserAdmin && !isUserMerchandise}
              />)}
          </Form.Item>
          <Form.Item>
            <UppercaseButton type="primary" htmlType="submit" disabled={!isUserAdmin && !isUserMerchandise && !isUserCP && !isUserStoreAdmin}>
              {intl.formatMessage({ id: 'display.save' })}
            </UppercaseButton>
          </Form.Item>
        </Form>
    }

    return (
      <section>
        
          <div className="contentContainer">
            {content}
          </div>

      </section>
    )
  }
}

const mapStateToProps = state => ({
  isUserAdmin: isUserAdmin(state),
  isUserStoreAdmin: isUserStoreAdmin(state),
  isUserCP: isUserCP(state),
  isUserMerchandise: isUserMerchandise(state),
  status: state.display.status,
  errorCode: state.display.errorCode,
  user: state.user,
  display: state.display.item
})
const mapDispatchToProps = {
  saveNewDisplay,
  updateDisplay,
  getDisplay,
  updateLocalDisplayData,
  resetDisplay,
  updateLinePickerYear,
}
export default injectIntl(Form.create()(connect(mapStateToProps, mapDispatchToProps)(Display)))
