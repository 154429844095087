import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { FormattedMessage, injectIntl } from 'react-intl'
import Loader from 'Components/Loader'
import YearPicker from 'Components/YearPicker'
import { connect } from 'react-redux'

import {
  getSummaryPageByYear,
  changeListPage,
  updateSummaryYearAndGetSummaryPageByYear,
  resetSummaryList
} from 'Redux/actions/summary-actions'

import { PageTitle } from 'Common/styled'
import { PageHeader, Table } from 'antd'

import './index.css'
class Summary extends Component {
  constructor(props) {
    super(props)

    this.state = { windowWidth: window.innerWidth };
  }

  static propTypes = {
    year: PropTypes.string,
    status: PropTypes.string,
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    totalItems: PropTypes.number,
    lines: PropTypes.arrayOf(PropTypes.object),
    stores: PropTypes.arrayOf(PropTypes.object),
    displays: PropTypes.arrayOf(PropTypes.object),
    getSummaryPageByYear: PropTypes.func,
    changeListPage: PropTypes.func,
    updateSummaryYearAndGetSummaryPageByYear: PropTypes.func,
    resetSummaryList: PropTypes.func,
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getSummaryList(0)
  }

  componentWillUnmount() {
    const { resetSummaryList } = this.props
    window.removeEventListener("resize", this.handleResize);
    resetSummaryList()
  }

  getSummaryList(page) {
    const { getSummaryPageByYear, pageSize, year } = this.props
    getSummaryPageByYear(year, page, pageSize)
  }

  changeLinePage(page) {
    const { changeListPage, pageSize, year } = this.props
    changeListPage(year, page, pageSize)
    
  }

  updateYearAndGetSummaryList(year) {
    const { updateSummaryYearAndGetSummaryPageByYear, pageSize } = this.props
    updateSummaryYearAndGetSummaryPageByYear(year, 0, pageSize)
  }

  nextPath(path) {
    const { history } = this.props
    history.push(path)
  }

  renderSummaryTable() {
    return (
      <div className="tableDiv">
        <table className="summaryTable">
          <thead>
            <th className="storeFirstTh"></th>
            {this.renderListHeader()}
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>

    )
  }

  getTableColumns() {
    const { stores, intl } = this.props
    const { windowWidth } = this.state

    let lineColumnsWidth =  windowWidth < 600 ? 125 : 250
    let storeColumnsWidth = 55
    let separatorsWith = 15

    const columns = [
      {
        width: lineColumnsWidth,
        key: 'lineName',
        dataIndex: 'lineName',
        fixed: 'left',
        title: intl.formatMessage({id: 'line'})
      }
    ]

    let columnsWidth = lineColumnsWidth

    stores.forEach((store, index) => {
      const prevElement = index - 1 >= 0 ? stores[index - 1] : null

      if (prevElement && store.regionId !== prevElement.regionId && index < stores.length) {
        columns.push(
          {
            width: separatorsWith,
            key: `separator-${store.storeId}`,
            dataIndex: `separator-${store.storeId}`
          }
        )
        columnsWidth += separatorsWith
      }

      columns.push(
        {
          align: 'center',
          width: storeColumnsWidth,
          key: store.storeId,
          dataIndex: `store-${store.storeId}`,
          title: store.storeId
        }
      )
      columnsWidth += storeColumnsWidth
    })
    return { columns, columnsWidth }
  }

  getTableData(columns) {
    const { lines, displays } = this.props
    const data = []

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i]
      const row = {
        key: line.id,
        lineName: line.name
      }
      console.log(lines)
      console.log(displays)
      columns.forEach((column) => {
        if (column.dataIndex.includes('separator')) {
          row[column.dataIndex] = ''
        }

        if (column.dataIndex.includes('store')) {
          if (displays.filter((display) => {
            return display.lineId === line.id && display.storeId === column.key
          }).length > 0) {
            row[column.dataIndex] = '✅'
          } else {
            row[column.dataIndex] = '❌'
          }
        }

      })

      data.push(row)
    }
    return data
  }

  renderTable() {
    const { pageSize, currentPage, totalItems, status } = this.props
    const columnsData = this.getTableColumns()

    return (
      <Table
        bordered
        loading={status === 'listLoading'}
        columns={columnsData.columns}
        pagination={{
          onChange: page => {
            console.log(`Retrieving page ${page - 1}`);
            this.changeLinePage(page - 1)
          },
          pageSize: pageSize,
          current: currentPage + 1,
          total: totalItems,
          responsive: true,
          simple: true,
        }}
        dataSource={this.getTableData(columnsData.columns)}
        scroll={{ x: columnsData.columnsWidth }} />
    )
  }

  render() {
    const { lines, year, status, intl } = this.props

    let content
    if (status === 'idle' || status === 'pageLoading') {
      content = <Loader />
    } else {
      if (isEmpty(lines) && status === 'fulfilled') {
        content =
          <PageTitle>
            <FormattedMessage id={'lines.no.results'} />
          </PageTitle>
      } else {
        content = this.renderTable()
      }
    }

    console.log(`content`, content)

    return (
      <section>
        <PageHeader
          title={intl.formatMessage({ id: 'home.summary.button' })} />
        <div className="filterContainer">
          <div className="filterRow">
            <YearPicker
              standardLabel={true}
              label='lines.filter.year'
              date={year}
              onDateChange={(date) => {
                console.log(`onDateChange date: ${date}`)
                this.updateYearAndGetSummaryList(date.year().toString())
              }}
            />
          </div>
        </div>
        <div className="contentContainer">
          {content}
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  year: state.summary.year,
  status: state.summary.status,
  currentPage: state.summary.currentPage,
  pageSize: state.summary.pageSize,
  totalItems: state.summary.totalItems,
  lines: state.summary.lines,
  stores: state.summary.stores,
  displays: state.summary.displays,
})
const mapDispatchToProps = {
  getSummaryPageByYear,
  changeListPage,
  updateSummaryYearAndGetSummaryPageByYear,
  resetSummaryList,
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Summary))
