import { createStore, compose, applyMiddleware } from 'redux'
import combinedReducer from 'Redux/reducers/combinedReducer'
import thunk from 'redux-thunk'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  combinedReducer,
  composeEnhancer(applyMiddleware(thunk))
)

//store.subscribe(() => console.log(store.getState()))
export default store
