import { 
    GET_DEPARTMENT_PICKER_DATA_REQUEST, 
    GET_DEPARTMENT_PICKER_DATA_SUCCESS, 
    GET_DEPARTMENT_PICKER_DATA_ERROR,
    UPDATE_DEPARTMENT_PICKER_VALUE,
    RESET_DEPARTMENT_PICKER_ITEMS
} from '../action-types'

const initialState = {
    departmentId: null,
    status: 'idle',
    items: []
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_DEPARTMENT_PICKER_DATA_REQUEST:
            return {...state, status: 'loading' }
        case GET_DEPARTMENT_PICKER_DATA_SUCCESS:
            return {
                ...state,
                status: 'fulfilled',
                items: payload.items
            }
        case GET_DEPARTMENT_PICKER_DATA_ERROR:
            return { ...state, status: 'error' }
        case UPDATE_DEPARTMENT_PICKER_VALUE:
            return { ...state, departmentId: payload }
        case RESET_DEPARTMENT_PICKER_ITEMS:
            return {...state, status: 'idle', items: [] }
        default:
            return state
    }
}