import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { FormattedMessage, injectIntl } from 'react-intl'
import Loader from 'Components/Loader'
import DepartmentPicker from 'Components/DepartmentPicker'
import { getMomentDate, getMomentYearDate } from 'Common/utils'
import { connect } from 'react-redux'

import { saveNewLine, updateLine, getLine, updateLocalLineData, resetLine } from 'Redux/actions/line-actions'
import { isUserAdmin } from 'Redux/selectors/user-selector'

import { PageTitle, UppercaseButton } from 'Common/styled'
import {
  Form,
  Input,
  DatePicker
} from 'antd';

import './index.css'
class Line extends Component {
  constructor(props) {
    super(props)

    this.formRef = React.createRef();

    this.state = {
      id: null,
      year: new Date().getFullYear().toString(),      
      isYearPickerOpen: false,
    }
  }

  static propTypes = {
    status: PropTypes.string,
    isUserAdmin: PropTypes.bool,
    line: PropTypes.object,
    saveNewLine: PropTypes.func,
    getLine: PropTypes.func,
    updateLocalLineData: PropTypes.func,
  }

  componentDidMount() {
    const { isUserAdmin } = this.props;

    if (isUserAdmin) {
      const { lineId } = this.props.match.params
      if (lineId) {
        this.getProductLine(lineId)
      }
    }
  }

  componentDidUpdate() {
    const { status, history } = this.props
    if (status === 'saved') {
      history.push('/lines')
    }
  }

  componentWillUnmount() {
    const { resetLine } = this.props
    resetLine()
  }

  saveNewProductLine(newLine) {
    const { saveNewLine } = this.props
    saveNewLine(newLine)
  }

  updateProductLine(lineId, lineToUpdate) {
    const { updateLine } = this.props
    console.log(`updateProductLine id ${lineId}:`, lineToUpdate)
    updateLine(lineId, lineToUpdate)
  }

  getProductLine(lineId) {
    const { getLine } = this.props
    console.log(lineId)
    getLine(lineId)
    this.setState({
      id: lineId
    })
  }

  nextPath(path) {
    const { history } = this.props
    history.push(path)
  }

  updateLineDepartment(department) {
    const { updateLocalLineData, line } = this.props
    
    this.props.form.setFieldsValue({
      departmentId: department,
    });

    const updatedLine = {
      ...line,
      departmentId: department
    }

    updateLocalLineData(updatedLine)
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { id } = this.state
        
        console.log(`id`, id)
        const line = {
          name: values.name.trim(),
          departmentId: values.department.trim(),
          kind: values.kind.trim(),
          storeAvailability: values.storeAvailability.format('YYYY-MM-DD'),
          year: values.year.year().toString(),
        }
        
        console.log('Line from form: ', line);

        if(id) {
          this.updateProductLine(id, {...line, id: parseFloat(id)})
          return
        }
        this.saveNewProductLine(line)
      }
    });
  };

  render() {
    const { year, isYearPickerOpen } = this.state
    const {status, line, isUserAdmin, intl } = this.props

    let content

    if (status === 'loading') {
      content = <Loader />
    } else if ((status === 'error' && isEmpty(line))) {
      content =
        <PageTitle>
          <FormattedMessage id={'line.line.not.found'} />
        </PageTitle>
    } else {
      const { getFieldDecorator } = this.props.form

      content =
        <Form
          ref={this.formRef}
          layout="vertical"
          size="default"
          onSubmit={this.handleSubmit}
        >
          <Form.Item label={intl.formatMessage({ id: 'line.name' })}>
            {getFieldDecorator('name', {
              initialValue: isEmpty(line) ? '' : line.name,
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: intl.formatMessage({ id: 'line.name.empty' }),
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'line.department' })}>
            {getFieldDecorator('department', {
              initialValue: isEmpty(line) ? undefined : line.departmentId,
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: intl.formatMessage({ id: 'line.department.empty' }),
                },
              ],
            })(
              <DepartmentPicker
                allowClear={false}
                disabled={!isUserAdmin}
                department={!isEmpty(line) && !line.departmentId ? undefined : line.departmentId}
                onDepartmentChange={(department) => {
                  this.updateLineDepartment(department)
                }}
              />
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'line.kind' })}>
            {getFieldDecorator('kind', {
              initialValue: isEmpty(line) ? '' : line.kind,
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: intl.formatMessage({ id: 'line.kind.empty' }),
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'line.availability.date' })}>
            {getFieldDecorator('storeAvailability', {
              initialValue: isEmpty(line) || !line.storeAvailability? null : getMomentDate(line.storeAvailability),
              rules: [
                { 
                  type: 'object', 
                  required: true, 
                  message: intl.formatMessage({ id: 'line.availability.date.empty' }) 
                  },
              ],
            })(<DatePicker allowClear={false}/>)}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'line.year' })}>
            {getFieldDecorator('year', {
              initialValue: isEmpty(line) ? getMomentYearDate(year): getMomentYearDate(line.year),
              rules: [
                { 
                  type: 'object',
                  required: true,
                  message: intl.formatMessage({ id: 'line.year.empty' }) 
                },
              ],
            })(
                <DatePicker
                  open={isYearPickerOpen}
                  onFocus={() => {
                    this.setState({isYearPickerOpen: true})
                  }}
                  onOpenChange={status => {
                    this.setState({isYearPickerOpen: status})
                  }}
                  onPanelChange={(date) => {
                      this.props.form.setFieldsValue({
                        year: date,
                      })
                      this.setState({isYearPickerOpen: false})
                  }}
                  format= "YYYY"
                  mode="year"
                  allowClear={false}
                  placeholder={intl.formatMessage({ id: 'year.picker.placeholder'})}
              />
              )
            }
          </Form.Item>
          <Form.Item>
            <UppercaseButton type="primary" htmlType="submit">
              {intl.formatMessage({ id: 'line.save' })}
            </UppercaseButton>
          </Form.Item>
        </Form>
    }

    return (
      <section>
        {!isUserAdmin ?
          <PageTitle>
            <FormattedMessage id={'line.no.admin'} />
          </PageTitle> :
          <div className="contentContainer">
            {content}
          </div>
        }
      </section>
    )
  }
}

const mapStateToProps = state => ({
  isUserAdmin: isUserAdmin(state),
  status: state.line.status,
  user: state.user,
  line: state.line.item
})
const mapDispatchToProps = {
  saveNewLine,
  updateLine,
  getLine,
  updateLocalLineData,
  resetLine,
}
export default injectIntl(Form.create()(connect(mapStateToProps, mapDispatchToProps)(Line)))
