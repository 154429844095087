import { 
    GET_SUMMARY_DATA_REQUEST,
    GET_SUMMARY_DATA_SUCCESS,
    GET_SUMMARY_DATA_ERROR,
    GET_SUMMARY_PAGE_REQUEST, 
    RESET_SUMMARY_DATA,
    UPDATE_SUMMARY_YEAR,
} from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getSummaryPageByYear = (year, pageNumber, pageSize) => async dispatch => {
    console.log(`getSummaryPageByYear: year ${year}, pageNumber ${pageNumber}, pageSize ${pageSize}`)
    dispatch({ type: GET_SUMMARY_DATA_REQUEST }) 
    
    const otherDataPageSize = Math.pow(2, 31) - 1

    try {        
        const linesRes = await api.lines.getPageByYear({ year, store: null, pageNumber, pageSize })
        const storesRes = await api.storesWithDisplay.getPageByFilters({ year, regionId: null, storeName: null, lineId: null, pageNumber: 0, pageSize: otherDataPageSize})
        const displaysRes = await api.displays.getPageByYearStoreDeptAndLineId({ year, storeId: null, departmentId: null, lineId: null, pageNumber: 0, pageSize: otherDataPageSize })
        
        setTimeout( ()=> {
            const linesList = linesRes.data
            const storesList = storesRes.data
            const displaysList = displaysRes.data
            return dispatch({ type: GET_SUMMARY_DATA_SUCCESS, payload: { linesList, storesList, displaysList } })
        }, 200)
    } catch (error) {
        dispatch({ type: GET_SUMMARY_DATA_ERROR }) 
        return handleServerError(error)
    }
}

export const changeListPage = (year, pageNumber, pageSize) => async dispatch => {
    console.log(`changeListPage: year ${year}, pageNumber ${pageNumber}, pageSize ${pageSize}`)
    dispatch({ type: GET_SUMMARY_PAGE_REQUEST })

    const otherDataPageSize = Math.pow(2, 31) - 1

    try {        
        const linesRes = await api.lines.getPageByYear({ year, store: null, pageNumber, pageSize })
        const storesRes = await api.storesWithDisplay.getPageByFilters({year, regionId: null, storeName: null, lineId: null, pageNumber: 0, pageSize: otherDataPageSize})
        // const storesRes = await api.storesWithDisplay.getPageByYearAndLineId({ year, lineId: null, pageNumber: 0, pageSize: otherDataPageSize})
        const displaysRes = await api.displays.getPageByYearStoreDeptAndLineId({ year, storeId: null, departmentId: null, lineId: null, pageNumber: 0, pageSize: otherDataPageSize })
        
        setTimeout( ()=> {
            const linesList = linesRes.data
            const storesList = storesRes.data
            const displaysList = displaysRes.data
            return dispatch({ type: GET_SUMMARY_DATA_SUCCESS, payload: { linesList, storesList, displaysList } })
        }, 200)
    } catch (error) {
        console.log(error);
        dispatch({ type: GET_SUMMARY_DATA_ERROR }) 
        return handleServerError(error)
    }
}

export const resetSummaryList = () => async dispatch => {
    console.log(`resetSummaryList`)
    return dispatch({ type: RESET_SUMMARY_DATA })
}

export const updateSummaryYearAndGetSummaryPageByYear = (year, pageNumber, pageSize) => async dispatch => { 
    console.log(`updateSummaryYearAndGetSummaryPageByYear: ${year}`)
    dispatch({ type: RESET_SUMMARY_DATA })
    dispatch({ type: UPDATE_SUMMARY_YEAR, payload: year })
    dispatch(getSummaryPageByYear(year, pageNumber, pageSize))
}
