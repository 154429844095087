import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { FormattedMessage, injectIntl } from 'react-intl'
import Loader from 'Components/Loader'
import YearPicker from 'Components/YearPicker'
import StorePicker from 'Components/StorePicker'
import DepartmentPicker from 'Components/DepartmentPicker'
import LinePicker from 'Components/LinePicker'
import { connect } from 'react-redux'

import {
    getDisplaysPageByYearStoreDeptAndLineId,
    updateDisplayFiltersAndGetDisplaysPage,
    updateDisplayYearGetDisplaysPage,
    updateDisplayStoreGetDisplaysPage,
    changeDisplaysListPage,
    resetDisplaysList
} from 'Redux/actions/displays-actions'

import {
    updateStorePickerValue
} from 'Redux/actions/store-picker-actions'

import {
    updateLinePickerYear,
    updateLinePickerDepartment
} from 'Redux/actions/line-picker-actions'

import { isUserAdmin, isUserCP, isUserMerchandise, isUserStoreAdmin } from 'Redux/selectors/user-selector'

import { PageTitle, Label, UppercaseButton } from 'Common/styled'
import { CarouselDiv } from './styled'

import { List, Carousel, Icon, PageHeader } from 'antd'

import config from 'Common/config'

import './index.css'

class Displays extends Component {
    static propTypes = {
        storeName: PropTypes.string,
        status: PropTypes.string,
        currentPage: PropTypes.number,
        pageSize: PropTypes.number,
        totalItems: PropTypes.number,
        displays: PropTypes.arrayOf(PropTypes.object),
        getDisplaysPageByYearStoreDeptAndLineId: PropTypes.func,
        changeDisplaysListPage: PropTypes.func,
        updateDisplayYearGetDisplaysPage: PropTypes.func,
        updateDisplayStoreGetDisplaysPage: PropTypes.func,
        updateDisplayFiltersAndGetDisplaysPage: PropTypes.func,
        resetDisplaysList: PropTypes.func,
        isUserAdmin: PropTypes.bool,
        isUserStoreAdmin: PropTypes.bool,
        isUserCP: PropTypes.bool,
        isUserMerchandise: PropTypes.bool        
    }

    componentDidMount() {
        const { location, history, updateDisplayFiltersAndGetDisplaysPage, pageSize, year, storeId, departmentId, lineId, updateLinePickerYear } = this.props
        const queryParams = new URLSearchParams(location.search)
        const yearParam = queryParams.get('year') || year
        
        let storeParam = queryParams.get('storeId') || storeId        
        let departmentParam = queryParams.get('departmentId') || departmentId
        let lineParam = queryParams.get('lineId') || lineId

        // if (queryParams.get('emptyStore')) {
        //     storeParam = undefined
        // }
        if (queryParams.get('emptyLine')) {
            lineParam = undefined
            departmentParam = undefined
        }

        updateDisplayFiltersAndGetDisplaysPage(yearParam, storeParam, departmentParam, lineParam, 0, pageSize).then(() => {
            queryParams.delete('year')
            queryParams.delete('storeId')
            queryParams.delete('departmentId')
            queryParams.delete('lineId')
            queryParams.delete('emptyStore')
            queryParams.delete('emptyLine')
            history.replace(`${location.pathname}?${queryParams.toString()}`)
        })

        updateLinePickerYear(year)

    }

    componentWillUnmount() {
        const { resetDisplaysList } = this.props
        resetDisplaysList()
    }

    changeDisplayPage(page) {
        const { changeDisplaysListPage, year, storeId, departmentId, lineId, pageSize } = this.props
        changeDisplaysListPage(year, storeId, departmentId, lineId, page, pageSize)
    }

    updateYearAndGetDisplaysList(year) {
        const { storeId, pageSize, updateDisplayYearGetDisplaysPage, departmentId, updateLinePickerYear } = this.props
        updateDisplayYearGetDisplaysPage(year, storeId, departmentId, 0, pageSize)
        updateLinePickerYear(year)
    }

    updateStoreAndGetDisplaysList(storeId) {
        const { year, lineId, departmentId, pageSize, updateDisplayFiltersAndGetDisplaysPage } = this.props
        updateDisplayFiltersAndGetDisplaysPage(year, storeId, departmentId, lineId, 0, pageSize)
    }

    updateDepartmentAndGetDisplaysList(departmentId) {
        const { year, storeId, pageSize, updateDisplayFiltersAndGetDisplaysPage, updateLinePickerDepartment } = this.props
        updateDisplayFiltersAndGetDisplaysPage(year, storeId, departmentId, undefined, 0, pageSize)
        updateLinePickerDepartment(year, departmentId)
    }

    updateLineAndGetDisplaysList(lineId) {
        const { year, storeId, departmentId, pageSize, updateDisplayFiltersAndGetDisplaysPage } = this.props
        updateDisplayFiltersAndGetDisplaysPage(year, storeId, departmentId, lineId, 0, pageSize)
    }

    nextPath(path) {
        const { history } = this.props
        history.push(path)
    }

    render() {
        const { storeId, departmentId, lineId, displays, year, pageSize, currentPage, totalItems, status, intl, isUserAdmin, isUserStoreAdmin, isUserCP, isUserMerchandise, user } = this.props
        let content
        if (status === 'idle' || status === 'pageLoading') {
            content = <Loader />
        } else {
            if (status === 'error') {
                content =
                    <PageTitle>
                        <FormattedMessage id={'lineStores.no.results'} />
                    </PageTitle>
            } else if (isEmpty(displays) && status === 'fulfilled') {
                content =
                    <PageTitle>
                        <FormattedMessage id={'displays.no.results'} />
                    </PageTitle>
            } else {
                content =
                    <List
                        itemLayout="vertical"
                        loading={status === 'listLoading'}
                        bordered
                        pagination={{
                            onChange: page => {
                                console.log(`Page size ${pageSize}`);
                                console.log(`Retrieving page ${page - 1}`);
                                this.changeDisplayPage(page - 1)
                            },
                            pageSize: pageSize,
                            current: currentPage + 1,
                            total: totalItems,
                            responsive: true,
                            simple: true,
                        }}
                        dataSource={displays}
                        className={isUserAdmin || isUserStoreAdmin ? 'listStyle' : ''}
                        renderItem={item => {

                            const Arrow = ({ type, style, className, onClick }) => (
                                <Icon type={type} style={style} className={className} onClick={onClick} />
                            );

                            const carouselContent =
                                item.pictures.map((picture) =>
                                    <div key={picture.id}>
                                        <img className="carouselImage" 
                                        alt="Product display images" 
                                        src={config.API_URL + `/productDisplayImage/${picture.uuid}`}
                                         />
                                    </div>
                                )
                            

                            console.log(item);
                            var cpFeedbackContent = '';
                            if (item.cpFeedback!==null && item.cpFeedback !== '') {
                                cpFeedbackContent = <div>
                                                        <b><FormattedMessage id={'lineStores.display.cpFeed'} /></b>
                                                        {item.cpFeedback.substr(0,20) + '...'}    
                                                    </div>
                                ;
                            } 

                            var mercFeedbackContent = '';
                            if (item.merchandiseFeedback!==null && item.merchandiseFeedback !== '') {
                                mercFeedbackContent = <div>
                                                        <b><FormattedMessage id={'lineStores.display.mercFeed'} /></b>
                                                        {item.merchandiseFeedback.substr(0,20) + '...'}    
                                                    </div>
                                ;
                            } 
                            return (
                                <List.Item
                                    onClick={() => {
                                        if (isUserAdmin || 
                                            (isUserStoreAdmin && user.storeId === item.storeId) ||
                                            isUserCP || 
                                            isUserMerchandise) {
                                            this.nextPath('/display/' + item.id)
                                        }
                                    }}
                                    key={item.storeId}
                                    extra={
                                        item.pictures.length > 0 ?
                                            <CarouselDiv>
                                                <Carousel
                                                    dots={true}
                                                    infinite={false}
                                                    arrows={true}
                                                    prevArrow={<Arrow type="left" />}
                                                    nextArrow={<Arrow type="right" />}>
                                                    {carouselContent}
                                                </Carousel>
                                            </CarouselDiv>
                                            :
                                            <></>
                                    }>
                                    <List.Item.Meta
                                        title={
                                            <Link className={!(isUserAdmin || 
                                                                (isUserStoreAdmin && user.storeId === item.storeId) ||
                                                                isUserCP || 
                                                                isUserMerchandise) ? 'disabled-link' : ''} to={`/display/${item.id}`}>
                                                {item.lineName && item.storeName ? `${item.storeName} - ${item.lineName}` : ''}
                                            </Link>
                                            }
                                        description={
                                            <div>
                                                <div>
                                                    <b><FormattedMessage id={'lineStores.store.number'} /></b>
                                                    {item.storeId && item.storeId}
                                                </div>
                                                <div>
                                                    <b><FormattedMessage id={'lines.line.department'} /></b>
                                                    {item.lineDepartment && item.lineDepartment}
                                                </div>
                                                <div>
                                                    <b><FormattedMessage id={'lines.line.kind'} /></b>
                                                    {item.lineKind && item.lineKind}
                                                </div>
                                                <div>
                                                    <b><FormattedMessage id={'lineStores.display.date'} /></b>
                                                    {item.displayDate && item.displayDate.toString()}
                                                </div>
                                                <div>
                                                    <b><FormattedMessage id={'lineStores.linear.meters'} /></b>
                                                    {item.linearMeters && item.linearMeters}
                                                </div>
                                                {cpFeedbackContent}
                                                {mercFeedbackContent}
                                            </div>
                                        }
                                    />
                                    {item.content}
                                </List.Item>
                            )
                        }}
                    />
            }
        }

        return (
            <section>
                <PageHeader
                    title={intl.formatMessage({ id: 'displays.view' })} />
                <div className="filterContainer">
                    <div className="filterRow">
                        <Label><FormattedMessage id={'filter.by'} /></Label>
                    </div>
                    <div className="filterRow">
                        <YearPicker
                            label='year'
                            date={year}
                            onDateChange={(date) => {
                                console.log(`onDateChange date: ${date}`)
                                this.updateYearAndGetDisplaysList(date.year().toString())
                            }}
                        />
                    </div>
                    <div className="filterRow">
                        <StorePicker
                            label={intl.formatMessage({ id: 'store' })}
                            store={storeId}
                            onStoreChange={(store) => {
                                console.log(`onStoreChange store: ${store}`)
                                this.updateStoreAndGetDisplaysList(store)
                            }}
                        />
                    </div>
                    <div className="filterRow">
                        <DepartmentPicker
                        label={intl.formatMessage({id: 'dept'})}
                        department={departmentId}
                        onDepartmentChange={(department) => {
                            console.log(`onDepartmentChange department: ${department}`)
                            this.updateDepartmentAndGetDisplaysList(department)
                        }}
                        />
                    </div>
                    <div className="filterRow">
                        <LinePicker
                            label={intl.formatMessage({ id: 'line' })}
                            year={year}
                            department={departmentId}
                            line={lineId}
                            onLineChange={(line) => {
                                console.log(`on Line changed: ${line}`)
                                this.updateLineAndGetDisplaysList(line)
                            }}
                        />
                    </div>
                </div>
                <div className="contentContainer">
                    {content}
                </div>
                <div className="buttonContainer" hidden={!(isUserAdmin || isUserStoreAdmin)}>
                    <UppercaseButton onClick={() => { this.nextPath('/newDisplay') }}>
                        <FormattedMessage id={'displays.new.display'} />
                    </UppercaseButton>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => ({
    storeId: state.displays.storeId,
    departmentId: state.displays.departmentId,
    lineId: state.displays.lineId,
    year: state.displays.year,
    status: state.displays.status,
    currentPage: state.displays.currentPage,
    pageSize: state.displays.pageSize,
    totalItems: state.displays.totalItems,
    displays: state.displays.items,
    user: state.user,
    isUserAdmin: isUserAdmin(state),
    isUserStoreAdmin: isUserStoreAdmin(state),
    isUserCP: isUserCP(state),
    isUserMerchandise: isUserMerchandise(state),
})
const mapDispatchToProps = {
    getDisplaysPageByYearStoreDeptAndLineId,
    updateDisplayYearGetDisplaysPage,
    updateDisplayStoreGetDisplaysPage,
    updateDisplayFiltersAndGetDisplaysPage,
    changeDisplaysListPage,
    resetDisplaysList,
    updateStorePickerValue,
    updateLinePickerYear,
    updateLinePickerDepartment
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Displays))
