import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Upload, Icon, Modal, Button } from 'antd'

import { injectIntl } from 'react-intl'
// import * as prop from 'ramda/src/prop';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

class PicturesWall extends Component {
    constructor(props) {
        super(props)
    
        this.formRef = React.createRef();
        console.log("DISABLED ---------- " + props.disabled)
        this.state = {
            previewVisible: false,
            previewImage: '',
            fileList: props.fileList,
            disabled: props.false ||  props.disabled
        };
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = ({ fileList }) => {
        this.setState({ fileList }, () => {
            this.props.onPicturesChange(fileList)
        })
    }

    render() {
        const { previewVisible, previewImage, fileList} = this.state;
        const { intl, disabled } = this.props;
        const uploadButton = (
            <Button>
                <Icon type="upload" />
                {intl.formatMessage({id: 'add'})}
            </Button>
        );
        return (
            <div className="clearfix">
                <Upload
                    className="upload-list-inline custom-list-inline"
                    customRequest={dummyRequest}
                    listType="picture"
                    fileList={fileList}
                    accept="image/png, image/jpeg"
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    disabled={disabled}
                >
                    {(fileList.length >= 5 || disabled) ? null : uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}
PicturesWall.propTypes = {
    onPicturesChange: PropTypes.func
}
PicturesWall.defaultProps = {
    disabled: false,
    fileList: [
    ],
}
export default injectIntl(PicturesWall)