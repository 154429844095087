import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import Auth from 'Containers/Auth'
import Home from 'Containers/Home'
import Line from 'Containers/Line'
import Lines from 'Containers/Lines'
import Stores from 'Containers/Stores'
import Displays from 'Containers/Displays'
import Display from 'Containers/Display'
import Summary from 'Containers/Summary'
import Page404 from 'Containers/404'
import Header from 'Components/Header'
import Footer from 'Components/Footer'
import FeedbackButton from 'Components/FeedbackButton'
import { WhiteLayout, PaddedContent } from 'Common/styled'

class App extends Component {
  static propTypes = {
    user: PropTypes.object,
  }
  componentDidMount() {
    const { user } = this.props

    this.tangram = new window.Tangram({
      productId: '49de97ab-8a9d-48a2-913b-3c82f386e4be', // PUT YOUR PRODUCT ID HERE.
      userLdap: user.LDAP,
      buttonOptions: {
        // This options is needed so you don't have two buttons.
        noShowOnStartup: true,
      },
    })
  }
  render() {
    const {
      user
    } = this.props
    return (
      <WhiteLayout>
        <Header
          user={user}
        />
        <Auth>
          <PaddedContent>
            <Switch>
              <Route component={Home} exact path={'/'} />
              <Route component={Lines} exact path={'/lines'} />
              <Route component={Line} exact path={'/newLine'} />
              <Route component={Line} exact path={'/line/:lineId'} />
              <Route component={Stores} exact path={'/stores'} />
              <Route component={Displays} exact path={'/displays'} />
              <Route component={Display} exact path={'/newDisplay'} />
              <Route component={Display} exact path={'/display/:displayId'} />
              <Route component={Summary} exact path={'/summary'} />
              <Route component={Page404} />
              <Redirect to="/" />
            </Switch>
          </PaddedContent>
        </Auth>
        <Footer />
        <FeedbackButton onClick={() => this.tangram.open()} />
      </WhiteLayout>
    )
  }
}
const mapStateToProps = state => ({
  user: state.user
})
const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(App)
