import {} from '../action-types'

export const initialState = {}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    default:
      return state
  }
}
