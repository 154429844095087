import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Row, Col } from 'antd'

import { Label } from './styled'

import { getMomentYearDate } from 'Common/utils'
import { FormattedMessage, injectIntl } from 'react-intl'

import './index.css'

const YearPicker = props => {
    const { label, date, onDateChange, intl, standardLabel } = props

    const [isOpen, setIsOpen] = useState(false)

    const datePicker =
        <DatePicker
            open={isOpen}
            value={date && getMomentYearDate(date)}
            onFocus={() => setIsOpen(true)}
            onOpenChange={status => {
                setIsOpen(status)
            }}
            onPanelChange={(date) => {
                onDateChange(date)
                setIsOpen(false)
            }}
            format="YYYY"
            mode="year"
            allowClear={false}
            placeholder={intl.formatMessage({ id: 'year.picker.placeholder' })}
        />

    if (standardLabel) {
        return (
            <div>
                {label ? <Label><FormattedMessage id={label}/></Label> : <></>}
                {datePicker}
            </div>
        )
    } else {
        return (
            <Row className="row">
                {label ?
                    <Col
                        xs={{ span: 4 }}
                        md={{ span: 3 }}
                        lg={{ span: 2 }}
                        xl={{ span: 1 }}>
                        <Label><FormattedMessage id={label} /></Label>
                    </Col>
                    : <></>
                }
                <Col
                    xs={{ span: 20 }}
                    md={{ span: 21 }}
                    lg={{ span: 22 }}
                    xl={{ span: 23 }}>
                    {datePicker}
                </Col>
            </Row>
        )
    }
}
YearPicker.defaultProps = {
    standardLabel: false
}
YearPicker.propTypes = {
    standardLabel: PropTypes.bool,
    label: PropTypes.string,
    date: PropTypes.string,
    onDateChange: PropTypes.func
}
export default injectIntl(YearPicker)
