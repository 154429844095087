const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)
const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-nuovegamme-itlm-ping-2ra5ah2pta-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    // API_URL: 'http://192.168.1.53:8080',
    //API_URL: 'http://192.168.1.40:8080',
    API_URL: 'https://test-nuovegamme-be-2ra5ah2pta-ew.a.run.app'
  },
  test: {
    LOGIN_URL: `https://test-lmit-nuovegamme-itlm-ping-2ra5ah2pta-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://test-nuovegamme-be-2ra5ah2pta-ew.a.run.app',
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-nuovegamme-itlm-ping-2ra5ah2pta-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://prod-nuovegamme-be-2ra5ah2pta-ew.a.run.app',
  },
}

export default envConfig[env]
