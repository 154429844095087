import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Select, Row, Col } from 'antd'

import { Label } from './styled'

import { injectIntl } from 'react-intl'

import {
    getStorePickerItems,
    updateStorePickerValue,
    resetStorePickerItems
} from 'Redux/actions/store-picker-actions'

import './index.css'

const StorePicker = props => {
    const { label, allowClear, disabled, store, onStoreChange, intl, items, status, getStorePickerItems, resetStorePickerItems } = props

    const { Option } = Select;

    useEffect(() => {
        if (items && items.length === 0 && status === 'idle') {
            getStorePickerItems()
        }
        return () => {
            if ((status === 'fulfilled' || status === 'error') && items && items.length > 0) {
                resetStorePickerItems() 
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, status])

    const options = items && items.map((item) => {
        return (
            <Option key={item.storeId}>{item.storeId} - {item.name}</Option>
        )
    })

    // console.log(`store: ${store}`)
    return (
        <Row className="row">
            {label ?
                <Col   
                    xs={{ span: 4 }}
                    md={{ span: 3}}
                    lg={{ span: 2 }}
                    xl={{ span: 1 }}>
                    <Label>{label}</Label>
                </Col> : <></>
            }
            <Col
                xs={{ span: 20 }}
                md={{ span: 21 }}
                lg={{ span: 22 }}
                xl={{ span: 23 }}>
                <Select
                    style={{ width: 225 }}
                    value={store}
                    allowClear={allowClear}
                    disabled={disabled}
                    loading={status === 'loading'}
                    placeholder={intl.formatMessage({ id: 'store.picker.placeholder' })}
                    onChange={(value) => {
                        onStoreChange(value)
                    }}
                >
                    {options}
                </Select>
            </Col>
        </Row>
    )
}
StorePicker.propTypes = {
    label: PropTypes.string,
    store: PropTypes.string,
    onStoreChange: PropTypes.func,
    allowClear: PropTypes.bool,
    disabled: PropTypes.bool
}
StorePicker.defaultProps = {
    allowClear: true,
    disabled: false
}
const mapStateToProps = state => ({
    status: state.storePicker.status,
    items: state.storePicker.items
})
const mapDispatchToProps = {
    getStorePickerItems,
    updateStorePickerValue,
    resetStorePickerItems
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(StorePicker))