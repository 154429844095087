import API from './axiosConfig'

// EXAMPLE APIS
// Reference: https://codeburst.io/how-to-call-api-in-a-smart-way-2ca572c6fe86 (Solution #2)
export default {
  user: {
    authenticate: token =>
      API.get('/user', { headers: { Authorization: `Bearer ${token}` } })
  },
  posts: {
    getAll: () => API.get('/posts'),
    getOne: ({ id }) => API.get(`/posts/${id}`),
    create: toCreate => API.put('/create-post', toCreate),
    update: toUpdate => API.put('/update-post', toUpdate),
    delete: ({ id }) => API.delete(`/posts/${id}`)
  },
  lines: {
    getPageByYear: ({year, store, department, name, pageNumber ,pageSize}) => {
      let url = `/productLine?year=${year}`
      if(store) {
        url += `&storeId=${store}`
      }
      if(department) {
        url += `&departmentId=${department}`
      }
      if(name) {
        url += `&lineName=${name}`
      }
      if (pageNumber) {
        url += `&pageNo=${pageNumber}`
      }
      if (pageSize) {
        url += `&pageSize=${pageSize}`
      }
      console.log(`getPageByYear url: ${url}`)
      return API.get(url)
    },
    getPickerItemsByYear: ({year, department}) => {
      let url = `/productLine/picker?year=${year}`
      if (department) {
        url += `&departmentId=${department}`
      }
      return API.get(url)
    }
  }, 
  line: {
    getOne: ({ id }) => API.get(`/productLine/${id}`),
    createLine: lineToCreate => API.post('/productLine', lineToCreate),
    updateLine: (id, lineToUpdate) => API.put( `/productLine/${id}`, lineToUpdate)
  },
  lineStores: {
    getLineStoresPageById: ({lineId, pageNumber ,pageSize}) => {
      let url = `/productLine/${lineId}/stores`
      if (pageNumber) {
        url += `?pageNo=${pageNumber}`
      }
      if (pageSize && pageNumber) {
        url += `&pageSize=${pageSize}`
      } else if (pageNumber){
        url += `?pageSize=${pageSize}`
      }
      console.log(`getLineStoresPageById url: ${url}`)
      return API.get(url)
    }
  },
  storesWithDisplay: {
    getPageByFilters: ({year, regionId, storeName, lineId, pageNumber ,pageSize}) => {
      let url = `/store/productDisplay?year=${year}`
      if(regionId) {
        url += `&regionId=${regionId}`
      }
      if(storeName) {
        url += `&storeName=${storeName}`
      }
      if(lineId) {
        url += `&lineId=${lineId}`
      }
      if (pageNumber) {
        url += `&pageNo=${pageNumber}`
      }
      if (pageSize) {
        url += `&pageSize=${pageSize}`
      }
      console.log(`getPageByYear url: ${url}`)
      return API.get(url)
    }
  },
  displays: {
    getPageByYearStoreDeptAndLineId: ({year, storeId, departmentId, lineId, pageNumber ,pageSize}) => {
      let url = `/productDisplay?year=${year}`
      if(storeId) {
        url += `&storeId=${storeId}`
      }
      if(departmentId) {
        url += `&departmentId=${departmentId}`
      }
      if(lineId) {
        url += `&lineId=${lineId}`
      }
      if (pageNumber) {
        url += `&pageNo=${pageNumber}`
      }
      if (pageSize) {
        url += `&pageSize=${pageSize}`
      }
      console.log(`getPageByYearStoreDeptAndLineId url: ${url}`)
      return API.get(url)
    }
  },
  display: {
    getOne: ({ id }) => API.get(`/productDisplay/${id}`),
    createDisplay: displayToCreate => API.post('/productDisplay', displayToCreate),
    updateDisplay: (id, displayToUpdate) => API.put( `/productDisplay/${id}`, displayToUpdate),
    addDisplayPicture: ({id}) => API.get(`/productDisplay/${id}/addImage`)
  },
  stores: {
    getOne: ({ id }) => API.get(`/store/${id}`),
    getAll: () => API.get(`/store`)
  },
  departments: {
    getAll: () => API.get(`/department/picker`)
  },
  regions: {
    getAll: () => API.get(`/region/picker`)
  }
}
