// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'

// TOUR
export const INIT_TOURS = 'INIT_TOURS'
export const SET_TOUR = 'SET_TOUR'
export const RESET_TOUR = 'RESET_TOUR'
export const RESET_ALL_TOURS = 'RESET_ALL_TOURS'
export const SET_TOUR_DATA = 'SET_TOUR_DATA'
export const RESET_TOUR_DATA = 'RESET_TOUR_DATA'

// LINES
export const GET_LINES_DATA_REQUEST = 'GET_LINES_DATA_REQUEST'
export const GET_LINES_DATA_SUCCESS = 'GET_LINES_DATA_SUCCESS'
export const GET_LINES_DATA_ERROR = 'GET_LINES_DATA_ERROR'
export const GET_LINES_LIST_PAGE_REQUEST = 'GET_LINES_LIST_PAGE_REQUEST'
export const RESET_LINES_DATA = 'RESET_LINES_DATA'
export const UPDATE_LINES_YEAR = 'UPDATE_LINES_YEAR'
export const UPDATE_LINES_STORE = 'UPDATE_LINES_STORE'
export const UPDATE_LINES_NAME = 'UPDATE_LINES_NAME'
export const UPDATE_LINES_DEPARTMENT = 'UPDATE_LINES_DEPARTMENT' 

// LINE
export const LINE_REQUEST = 'LINE_REQUEST'
export const LINE_ERROR = 'LINE_ERROR' 
export const SAVE_NEW_LINE = 'SAVE_NEW_LINE'
export const UPDATE_LINE = 'UPDATE_LINE'
export const GET_LINE_DATA = 'GET_LINE_DATA'
export const RESET_LINE_DATA = 'RESET_LINE_DATA'
export const UPDATE_LOCAL_LINE_DATA = 'UPDATE_LOCAL_LINE_DATA'

// STORES
export const GET_STORES_DATA_REQUEST = 'GET_STORES_DATA_REQUEST'
export const GET_STORES_DATA_SUCCESS = 'GET_STORES_DATA_SUCCESS'
export const GET_STORES_DATA_ERROR = 'GET_STORES_DATA_ERROR'
export const GET_STORES_LIST_PAGE_REQUEST = 'GET_STORES_LIST_PAGE_REQUEST'
export const RESET_STORES_DATA = 'RESET_STORES_DATA'
export const UPDATE_STORES_YEAR = 'UPDATE_STORES_YEAR'
export const UPDATE_STORES_LINE = 'UPDATE_STORES_LINE'
export const UPDATE_STORES_NAME = 'UPDATE_STORES_NAME'
export const UPDATE_STORES_REGION = 'UPDATE_STORES_REGION'

// DISPLAYS
export const GET_DISPLAYS_DATA_REQUEST = 'GET_DISPLAYS_DATA_REQUEST'
export const GET_DISPLAYS_DATA_SUCCESS = 'GET_DISPLAYS_DATA_SUCCESS'
export const GET_DISPLAYS_DATA_ERROR = 'GET_DISPLAYS_DATA_ERROR'
export const GET_DISPLAYS_LIST_PAGE_REQUEST = 'GET_DISPLAYS_LIST_PAGE_REQUEST'
export const RESET_DISPLAYS_DATA = 'RESET_DISPLAYS_DATA'
export const UPDATE_DISPLAYS_YEAR = 'UPDATE_DISPLAYS_YEAR'
export const UPDATE_DISPLAYS_STORE = 'UPDATE_DISPLAYS_STORE'
export const UPDATE_DISPLAYS_LINE = 'UPDATE_DISPLAYS_LINE'
export const UPDATE_DISPLAYS_DEPARTMENT = 'UPDATE_DISPLAYS_DEPARTMENT'

// STORE PICKER
export const GET_STORE_PICKER_DATA_REQUEST = 'GET_STORE_PICKER_DATA_REQUEST'
export const GET_STORE_PICKER_DATA_SUCCESS = 'GET_STORE_PICKER_DATA_SUCCESS'
export const GET_STORE_PICKER_DATA_ERROR = 'GET_STORE_PICKER_DATA_ERROR'
export const UPDATE_STORE_PICKER_VALUE = 'UPDATE_STORE_PICKER_VALUE'
export const RESET_STORE_PICKER_ITEMS = 'RESET_STORE_PICKER_ITEMS'

// LINE PICKER
export const GET_LINE_PICKER_DATA_REQUEST = 'GET_LINE_PICKER_DATA_REQUEST'
export const GET_LINE_PICKER_DATA_SUCCESS = 'GET_LINE_PICKER_DATA_SUCCESS'
export const GET_LINE_PICKER_DATA_ERROR = 'GET_LINE_PICKER_DATA_ERROR'
export const UPDATE_LINE_PICKER_VALUE = 'UPDATE_LINE_PICKER_VALUE'
export const UPDATE_LINE_PICKER_YEAR = 'UPDATE_LINE_PICKER_YEAR'
export const UPDATE_LINE_PICKER_DEPARTMENT = 'UPDATE_LINE_PICKER_DEPARTMENT'
export const RESET_LINE_PICKER_ITEMS = 'RESET_LINE_PICKER_ITEMS'

// DISPLAY
export const DISPLAY_REQUEST = 'DISPLAY_REQUEST'
export const DISPLAY_ERROR = 'DISPLAY_ERROR' 
export const SAVE_NEW_DISPLAY = 'SAVE_NEW_DISPLAY'
export const UPDATE_DISPLAY = 'UPDATE_DISPLAY'
export const GET_DISPLAY_DATA = 'GET_DISPLAY_DATA'
export const UPDATE_LOCAL_DISPLAY_DATA = 'UPDATE_LOCAL_DISPLAY_DATA'
export const RESET_DISPLAY_DATA = 'RESET_DISPLAY_DATA'

// SUMMARY
export const GET_SUMMARY_DATA_REQUEST = 'GET_SUMMARY_DATA_REQUEST'
export const GET_SUMMARY_DATA_SUCCESS = 'GET_SUMMARY_DATA_SUCCESS'
export const GET_SUMMARY_DATA_ERROR = 'GET_SUMMARY_DATA_ERROR'
export const GET_SUMMARY_PAGE_REQUEST = 'GET_SUMMARY_PAGE_REQUEST'
export const RESET_SUMMARY_DATA = 'RESET_SUMMARY_DATA'
export const UPDATE_SUMMARY_YEAR = 'UPDATE_SUMMARY_YEAR'

// DEPARTMENT PICKER
export const GET_DEPARTMENT_PICKER_DATA_REQUEST = 'GET_DEPARTMENT_PICKER_DATA_REQUEST'
export const GET_DEPARTMENT_PICKER_DATA_SUCCESS = 'GET_DEPARTMENT_PICKER_DATA_SUCCESS'
export const GET_DEPARTMENT_PICKER_DATA_ERROR = 'GET_DEPARTMENT_PICKER_DATA_ERROR'
export const UPDATE_DEPARTMENT_PICKER_VALUE = 'UPDATE_DEPARTMENT_PICKER_VALUE'
export const RESET_DEPARTMENT_PICKER_ITEMS = 'RESET_DEPARTMENT_PICKER_ITEMS'

// REGION PICKER
export const GET_REGION_PICKER_DATA_REQUEST = 'GET_REGION_PICKER_DATA_REQUEST'
export const GET_REGION_PICKER_DATA_SUCCESS = 'GET_REGION_PICKER_DATA_SUCCESS'
export const GET_REGION_PICKER_DATA_ERROR = 'GET_REGION_PICKER_DATA_ERROR'
export const UPDATE_REGION_PICKER_VALUE = 'UPDATE_REGION_PICKER_VALUE'
export const RESET_REGION_PICKER_ITEMS = 'RESET_REGION_PICKER_ITEMS'
