import { 
    GET_LINE_PICKER_DATA_REQUEST, 
    GET_LINE_PICKER_DATA_SUCCESS, 
    GET_LINE_PICKER_DATA_ERROR,
    UPDATE_LINE_PICKER_VALUE,
    UPDATE_LINE_PICKER_YEAR,
    RESET_LINE_PICKER_ITEMS,
} from '../action-types'

const initialState = {
    year: null,
    status: 'idle',
    items: []
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_LINE_PICKER_DATA_REQUEST:
            return {...state, status: 'loading' }
        case GET_LINE_PICKER_DATA_SUCCESS:
            return {
                ...state,
                status: 'fulfilled',
                items: payload.items
            }
        case GET_LINE_PICKER_DATA_ERROR:
            return { ...state, status: 'error' }
        case UPDATE_LINE_PICKER_VALUE:
            return { ...state, lineId: payload }
        case UPDATE_LINE_PICKER_YEAR:
            return { ...state, year: payload }
        case RESET_LINE_PICKER_ITEMS:
            return {...state, status: 'idle', items: [] }
        default:
            return state
    }
}