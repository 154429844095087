import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Select, Row, Col } from 'antd'

import { Label } from './styled'

import { injectIntl } from 'react-intl'

import {
    getDepartmentPickerItems,
    updateDepartmentPickerValue,
    resetDepartmentPickerItems
} from 'Redux/actions/department-picker-actions'

import './index.css'

const DepartmentPicker = props => {
    const { label, allowClear, disabled, department, onDepartmentChange, intl, items, status, getDepartmentPickerItems, resetDepartmentPickerItems } = props

    const { Option } = Select;

    useEffect(() => {
        if (items && items.length === 0 && status === 'idle') {
            getDepartmentPickerItems()
        }
        return () => {
            if ((status === 'fulfilled' || status === 'error') && items && items.length > 0) {
                resetDepartmentPickerItems() 
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, status])

    const options = items && items.map((item) => {
        return (
            <Option key={item.id}>{item.id} - {item.label}</Option>
        )
    })

    // console.log(`store: ${department}`)
    return (
        <Row className="row">
            {label ?
                <Col   
                    xs={{ span: 4 }}
                    md={{ span: 3}}
                    lg={{ span: 2 }}
                    xl={{ span: 1 }}>
                    <Label>{label}</Label>
                </Col> : <></>
            }
            <Col
                xs={{ span: 20 }}
                md={{ span: 21 }}
                lg={{ span: 22 }}
                xl={{ span: 23 }}>
                <Select
                    style={{ width: 225 }}
                    value={department}
                    allowClear={allowClear}
                    disabled={disabled}
                    loading={status === 'loading'}
                    placeholder={intl.formatMessage({ id: 'department.picker.placeholder' })}
                    onChange={(value) => {
                        onDepartmentChange(value)
                    }}
                >
                    {options}
                </Select>
            </Col>
        </Row>
    )
}
DepartmentPicker.propTypes = {
    label: PropTypes.string,
    department: PropTypes.string,
    onDepartmentChange: PropTypes.func,
    allowClear: PropTypes.bool,
    disabled: PropTypes.bool
}
DepartmentPicker.defaultProps = {
    allowClear: true,
    disabled: false
}
const mapStateToProps = state => ({
    status: state.departmentPicker.status,
    items: state.departmentPicker.items
})
const mapDispatchToProps = {
    getDepartmentPickerItems,
    updateDepartmentPickerValue,
    resetDepartmentPickerItems
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DepartmentPicker))