import {
    LINE_REQUEST,
    LINE_ERROR, 
    SAVE_NEW_LINE,
    UPDATE_LINE, 
    GET_LINE_DATA, 
    RESET_LINE_DATA,
    UPDATE_LOCAL_LINE_DATA 
} from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const saveNewLine = (line) => async dispatch => {
    dispatch({ type: LINE_REQUEST }) 
    try {
        console.log(`saveNewLine: ${JSON.stringify(line, null, 2)}`)
        const res = await api.line.createLine(line)
        const lineId = res.data
        console.log(`saveNewLine saved line id: ${lineId}`)
        setTimeout(() => {
            return dispatch({ type: SAVE_NEW_LINE })
        }, 200)
    } catch (error) {
        dispatch({ type: LINE_ERROR }) 
        return handleServerError(error)
    }
}

export const getLine = (id) => async dispatch => {
    dispatch({ type: LINE_REQUEST }) 

    try {
        const res = await api.line.getOne({ id })
        const line = res.data
        console.log(`getLine: ${JSON.stringify(line, null, 2)}`)
        setTimeout(() => {
            return dispatch({ type: GET_LINE_DATA, payload: line })
        }, 200)
    } catch (error) {
        dispatch({ type: LINE_ERROR }) 
        return handleServerError(error)
    }
}

export const updateLine = (id, line) => async dispatch => {

    dispatch({ type: LINE_REQUEST }) 
    try {
        console.log(`updateLine: ${JSON.stringify(line, null, 2)}`)
        const res = await api.line.updateLine(id, line)
        const status = res.status
        console.log(`updated line id: ${id}, status ${status}`)
        setTimeout(() => {
            return dispatch({ type: UPDATE_LINE, payload: line })
        }, 200)
    } catch (error) {
        dispatch({ type: LINE_ERROR }) 
        return handleServerError(error)
    }
}

export const resetLine = () => async dispatch => {
    return dispatch ({ type: RESET_LINE_DATA })
}

export const updateLocalLineData = (line) => async dispatch => {
    return dispatch({ type: UPDATE_LOCAL_LINE_DATA, payload: line })
}