import { 
    DISPLAY_REQUEST,
    DISPLAY_ERROR,
    SAVE_NEW_DISPLAY,
    UPDATE_DISPLAY,
    GET_DISPLAY_DATA,
    UPDATE_LOCAL_DISPLAY_DATA,
    RESET_DISPLAY_DATA 
} from '../action-types'

const initialState = {
    status: 'idle',
    errorCode: null,
    item: {
        year: new Date().getFullYear().toString(),
        pilot: false,
        pictures: [],
    }
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case DISPLAY_REQUEST:
            return { ...state, status: 'loading' }
        case SAVE_NEW_DISPLAY:
            return { ...state, status: 'saved' }
        case GET_DISPLAY_DATA:
            return { ...state, status: 'fulfilled', item: payload }
        case UPDATE_LOCAL_DISPLAY_DATA:
            return { ...state, item: payload }
        case UPDATE_DISPLAY:
            return { ...state, status: 'saved', item: payload }
        case DISPLAY_ERROR:
            return { ...state, status: 'error', errorCode: payload }
        case RESET_DISPLAY_DATA:
            return { ...state, ...initialState }
        default:
            return state
    }
}