import {
    DISPLAY_REQUEST,
    DISPLAY_ERROR,
    SAVE_NEW_DISPLAY,
    UPDATE_DISPLAY,
    GET_DISPLAY_DATA,
    UPDATE_LOCAL_DISPLAY_DATA,
    RESET_DISPLAY_DATA
} from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

import axios from 'axios'

export const saveNewDisplay = (display) => async dispatch => {
    dispatch({ type: DISPLAY_REQUEST })
    try {
        console.log(`saveNewDisplay: ${JSON.stringify(display, null, 2)}`)
        const res = await api.display.createDisplay({ ...display, pictures: [] })

        const displayId = res.data
        // console.log(`res data`,displayId)
        // console.log(`saveNewDisplay saved display id: ${displayId.result}`)

        // Upload images
        for (let i = 0; i < display.pictures.length; i++) {
            const picture = display.pictures[i]

            console.log(`updatedDisplay picture`, picture)
            if (picture.uid.includes('rc-upload')) {
                const pictureRes = await api.display.addDisplayPicture({ id: displayId.result })

                const gcsInfo = pictureRes.data

                const uploadRes = await axios.put(gcsInfo.url, picture.originFileObj, {
                    headers: {
                        'Content-Type': picture.originFileObj.type
                    }
                })
                console.log(`uploadRes`, uploadRes)
            }
        }

        setTimeout(() => {
            return dispatch({ type: SAVE_NEW_DISPLAY })
        }, 200)
    } catch (error) {
        dispatch({ type: DISPLAY_ERROR, payload: error.response.status })
        return handleServerError(error)
    }
}

export const getDisplay = (id) => async dispatch => {
    dispatch({ type: DISPLAY_REQUEST })

    try {
        const res = await api.display.getOne({ id })
        const display = res.data
        console.log(`getDisplay: ${JSON.stringify(display, null, 2)}`)
        setTimeout(() => {
            return dispatch({ type: GET_DISPLAY_DATA, payload: display })
        }, 200)
    } catch (error) {
        dispatch({ type: DISPLAY_ERROR, payload: error.response.status })
        return handleServerError(error)
    }
}

export const updateDisplay = (id, display) => async dispatch => {

    dispatch({ type: DISPLAY_REQUEST })

    try {
        console.log(`updateDisplay: ${JSON.stringify(display, null, 2)}`)

        const pictures = []

        for (let i = 0; i < display.pictures.length; i++) {
            const picture = display.pictures[i]

            console.log(`updatedDisplay picture`, picture)
            if (picture.uid.includes('rc-upload')) {

                const pictureRes = await api.display.addDisplayPicture({ id })

                const gcsInfo = pictureRes.data
                pictures.push({ uuid: gcsInfo.uuid })

                console.log(JSON.stringify(gcsInfo, null, 2))

                console.log(`picture`, picture)

                console.log(`picture file object`, picture.originFileObj)

                const uploadRes = await axios.put(gcsInfo.url, picture.originFileObj, {
                    headers: {
                        'Content-Type': picture.originFileObj.type
                    }
                })

                console.log(`uploaded image ${i}`)
                console.log(`uploadRes`, uploadRes)

            } else {
                pictures.push({ uuid: picture.uid })
            }

        }

        const res = await api.display.updateDisplay(id, { ...display, pictures })
        const status = res.status
        console.log(`updated display id: ${id}, status ${status}`)
        setTimeout(() => {
            return dispatch({ type: UPDATE_DISPLAY, payload: display })
        }, 200)
    } catch (error) {
        console.log(`error`, error)
        dispatch({ type: DISPLAY_ERROR, payload: error.response && error.response.status ? error.response.status : null })
        return handleServerError(error)
    }
}

export const resetDisplay = () => async dispatch => {
    return dispatch({ type: RESET_DISPLAY_DATA })
}

export const updateLocalDisplayData = (display) => async dispatch => {
    return dispatch({ type: UPDATE_LOCAL_DISPLAY_DATA, payload: display })
}