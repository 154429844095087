import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { FormattedMessage, injectIntl } from 'react-intl'
import Loader from 'Components/Loader'
import YearPicker from 'Components/YearPicker'
import RegionPicker from 'Components/RegionPicker'
import LinePicker from 'Components/LinePicker'
import TextSearch from 'Components/TextSearch'
import { connect } from 'react-redux'

import {
  getStoresPageByFilters,
  changeListPage,
  updateStoresYearAndGetStoresPage,
  updateStoresLineIdAndGetStoresPage,
  updateStoresRegionIdAndGetStoresPage,
  updateStoresRegionNameAndGetStoresPage,
  resetStoresList
} from 'Redux/actions/stores-actions'

import {
  updateLinePickerYear
} from 'Redux/actions/line-picker-actions'

import { isUserAdmin } from 'Redux/selectors/user-selector'
import { PageTitle, Label } from 'Common/styled'
import { List, PageHeader } from 'antd'

import './index.css'
class Stores extends Component {
  static propTypes = {
    year: PropTypes.string,
    storeName: PropTypes.string,
    status: PropTypes.string,
    user: PropTypes.object,
    isUserAdmin: PropTypes.bool,
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    totalItems: PropTypes.number,
    stores: PropTypes.arrayOf(PropTypes.object),
    getStoresPageByFilters: PropTypes.func,
    changeListPage: PropTypes.func,
    updateStoresYearAndGetStoresPage: PropTypes.func,
    updateStoresLineIdAndGetStoresPage: PropTypes.func,
    updateRegionAndGetStoresList: PropTypes.func,
    resetStoresList: PropTypes.func,
  }

  componentDidMount() {
    this.getStoresList(0)
  }

  componentWillUnmount() {
    const { resetStoresList } = this.props
    resetStoresList()
  }

  getStoresList(page) {
    const { getStoresPageByFilters, pageSize, year, regionId, storeName, lineId } = this.props
    getStoresPageByFilters(year, regionId, storeName, lineId, page, pageSize)
  }

  changeStoresPage(page) {
    const { changeListPage, pageSize, year, regionId, storeName, lineId } = this.props
    changeListPage(year, regionId, storeName, lineId, page, pageSize)
  }

  updateYearAndGetStoresList(year) {
    const { updateStoresYearAndGetStoresPage, regionId, storeName, pageSize } = this.props
    updateStoresYearAndGetStoresPage(year, regionId, storeName, 0, pageSize)
  }

  updateRegionAndGetStoresList(regionId) {
    const { updateStoresRegionIdAndGetStoresPage, year, lineId, storeName, pageSize } = this.props
    updateStoresRegionIdAndGetStoresPage(year, regionId, storeName, lineId, 0, pageSize)
  }

  updateLineAndGetStoresList(lineId) {
    const { updateStoresLineIdAndGetStoresPage, year, regionId, storeName, pageSize } = this.props
    updateStoresLineIdAndGetStoresPage(year, regionId, storeName, lineId, 0, pageSize)
  }

  updateStoreNameAndGetStoresList(storeName) {
    const { updateStoresRegionNameAndGetStoresPage, year, regionId, lineId, pageSize } = this.props
    updateStoresRegionNameAndGetStoresPage(year, regionId, storeName, lineId, 0, pageSize)
  }

  nextPath(path) {
    const { history } = this.props
    history.push(path)
  }

  render() {
    const { stores, pageSize, currentPage, totalItems, year, status, intl, regionId, lineId, storeName, updateLinePickerYear } = this.props

    let content
    if (status === 'idle' || status === 'pageLoading') {
      content = <Loader />
    } else {
      if (isEmpty(stores) && status === 'fulfilled') {
        content =
          <PageTitle>
            <FormattedMessage id={'stores.no.results'} />
          </PageTitle>
      } else {
        content =
          <List
            itemLayout="vertical"
            loading={status === 'listLoading'}
            bordered
            pagination={{
              onChange: page => {
                console.log(`Retrieving page ${page - 1}`);
                this.changeStoresPage(page - 1)
              },
              pageSize: pageSize,
              current: currentPage + 1,
              total: totalItems,
              responsive: true,
              simple: true,
            }}
            dataSource={stores}
            renderItem={item => (
              <List.Item
                key={item.storeId}
                actions={[
                  <Link
                    to={`/displays?year=${year}&storeId=${item.storeId}&emptyLine=true`}>
                    <FormattedMessage id={'stores.displayed.lines'} />
                    {item.linesNumber && item.linesNumber}
                  </Link>
                  ,
                ]}
              >
                <List.Item.Meta
                  title={item.name && item.name}
                  description={
                    <div>
                      <div>
                        <b><FormattedMessage id={'stores.store.number'} /></b>
                        {item.storeId && item.storeId}
                      </div>
                      <div>
                        <b><FormattedMessage id={'stores.region.number'} /></b>
                        {item.regionId && item.regionId}
                      </div>
                    </div>
                  }
                />
                {item.content}
              </List.Item>
            )}
          />
      }
    }

    return (
      <section>
        <PageHeader
          title={intl.formatMessage({ id: 'home.store.button' })} />
        <div className="filterContainer">
        <div className="filterRow">
            <Label><FormattedMessage id={'filter.by'}/></Label>
          </div>
          <div className="filterRow">
            <YearPicker
              label='year'
              date={year}
              onDateChange={(date) => {
                console.log(`onDateChange date: ${date}`)
                updateLinePickerYear(date.year().toString())
                this.updateYearAndGetStoresList(date.year().toString())
              }}
            />
          </div>
          <div className="filterRow">
            <RegionPicker
                label={intl.formatMessage({id: 'region'})}
                year={year}
                region={regionId}
                onRegionChange={(region) => {
                  console.log(`on Region changed: ${region}`)
                  this.updateRegionAndGetStoresList(region)
                }}
              />
          </div>
          <div className="filterRow">
            <LinePicker
                label={intl.formatMessage({id: 'line'})}
                year={year}
                line={lineId}
                onLineChange={(line) => {
                  console.log(`on Line changed: ${line}`)
                  this.updateLineAndGetStoresList(line)
                }}
              />
          </div>
          <div className="filterRow">
            <TextSearch
              label={intl.formatMessage({id: 'name'})}
              placeholder={intl.formatMessage({id: 'store.name.filter.placeholder'})}
              value={storeName}
              onSearch={(value) => {
                this.updateStoreNameAndGetStoresList(value)
              }}
            />
          </div>
        </div>
        <div className="contentContainer">
          {content}
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  lineId: state.stores.lineId,
  regionId: state.stores.regionId,
  storeName: state.stores.storeName,
  year: state.stores.year,
  status: state.stores.status,
  user: state.user,
  isUserAdmin: isUserAdmin(state),
  currentPage: state.stores.currentPage,
  pageSize: state.stores.pageSize,
  totalItems: state.stores.totalItems,
  stores: state.stores.items
})
const mapDispatchToProps = {
  getStoresPageByFilters,
  changeListPage,
  updateStoresYearAndGetStoresPage,
  updateStoresLineIdAndGetStoresPage,
  updateStoresRegionIdAndGetStoresPage,
  updateStoresRegionNameAndGetStoresPage,
  resetStoresList,
  updateLinePickerYear,
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Stores))
