import { 
    GET_REGION_PICKER_DATA_REQUEST,
    GET_REGION_PICKER_DATA_SUCCESS,
    GET_REGION_PICKER_DATA_ERROR,
    UPDATE_REGION_PICKER_VALUE,
    RESET_REGION_PICKER_ITEMS
} from '../action-types'
import api from 'Common/api'

export const getRegionPickerItems = () => async dispatch => {
    console.log(`getRegionPickerItems`)
    dispatch({ type: GET_REGION_PICKER_DATA_REQUEST }) 
    
    try {
        const res = await api.regions.getAll()
        setTimeout( ()=> {
            const regions = res.data
            return dispatch({ type: GET_REGION_PICKER_DATA_SUCCESS, payload: regions })
        }, 200)
    } catch (error) {
        dispatch({ type: GET_REGION_PICKER_DATA_ERROR }) 
    }
}

export const updateRegionPickerValue = (regionId) => async dispatch => { 
    console.log(`updateRegionPickerValue: new region id ${regionId}`)
    return dispatch({ type: UPDATE_REGION_PICKER_VALUE, payload: regionId })
}

export const resetRegionPickerItems = () => async dispatch => {
    console.log(`resetRegionPickerItems`)
    return dispatch({ type: RESET_REGION_PICKER_ITEMS })
}