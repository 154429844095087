import { 
    GET_STORES_DATA_REQUEST, 
    GET_STORES_DATA_SUCCESS,
    GET_STORES_DATA_ERROR,
    GET_STORES_LIST_PAGE_REQUEST, 
    RESET_STORES_DATA,
    UPDATE_STORES_YEAR,
    UPDATE_STORES_LINE,
    UPDATE_STORES_REGION,
    UPDATE_STORES_NAME
} from '../action-types'

const initialState = {
    year: new Date().getFullYear().toString(),
    regionId: undefined,
    storeName: undefined,
    lineId: undefined,
    currentPage: 0,
    pageSize: 5,
    totalItems: 0,
    status: 'idle',
    items: []
}
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_STORES_DATA_REQUEST:
            return {...state, status: 'pageLoading' }
        case GET_STORES_DATA_SUCCESS:
            const updatedStores = state.items.splice()
            for (const item of payload.results) {
                let isNew = true;

                for (let i = 0; i < updatedStores.length; i += 1) {
                    if (updatedStores[i].storeId === item.id) {
                        updatedStores[i] = { ...item };
                        isNew = false;
                    }
                }

                if (isNew) {
                    updatedStores.push({ ...item });
                }
            }

            return {
                ...state,
                currentPage: payload.pagingData.currentPage,
                pageSize: payload.pagingData.pageSize,
                totalItems: payload.pagingData.totalItems,
                status: 'fulfilled',
                items: updatedStores
            }
        case GET_STORES_DATA_ERROR:
            return { ...state, status: 'error' }
        case GET_STORES_LIST_PAGE_REQUEST:
            return { ...state, status: 'listLoading' }
        case RESET_STORES_DATA:
            return { ...state, items: [] }
        case UPDATE_STORES_YEAR:
            return { ...state, year: payload, lineId: undefined }
        case UPDATE_STORES_LINE:
            return { ...state, lineId: payload }
        case UPDATE_STORES_REGION:
            return { ...state, regionId: payload }
        case UPDATE_STORES_NAME:
            return { ...state, storeName: payload }
        default:
            return state
    }
}