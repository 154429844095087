import { 
    GET_LINES_DATA_REQUEST,
    GET_LINES_DATA_SUCCESS,
    GET_LINES_DATA_ERROR,
    GET_LINES_LIST_PAGE_REQUEST, 
    RESET_LINES_DATA,
    UPDATE_LINES_YEAR,
    UPDATE_LINES_STORE,
    UPDATE_LINES_DEPARTMENT,
    UPDATE_LINES_NAME,
} from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getLinesPageByYear = (year, store, department, name, pageNumber, pageSize) => async dispatch => {
    console.log(`getLinesPageByYear: year ${year}, store ${store}, department ${department}, name ${name}, pageNumber ${pageNumber}, pageSize ${pageSize}`)
    dispatch({ type: GET_LINES_DATA_REQUEST }) 
    
    try {        
        const res = await api.lines.getPageByYear({ year, store, department, name, pageNumber, pageSize })
        setTimeout( ()=> {
            const linesList = res.data
            // console.log(`lines list from api:`)
            // console.log(JSON.stringify(linesList, null, 2))
            return dispatch({ type: GET_LINES_DATA_SUCCESS, payload: linesList })
        }, 200)
    } catch (error) {
        dispatch({ type: GET_LINES_DATA_ERROR }) 
        return handleServerError(error)
    }
}

export const changeListPage = (year, store, department, name, pageNumber, pageSize) => async dispatch => {
    console.log(`changeListPage: year ${year}, store ${store}, department ${department}, name ${name}, pageNumber ${pageNumber}, pageSize ${pageSize}`)
    dispatch({ type: GET_LINES_LIST_PAGE_REQUEST })

    try {        
        const res = await api.lines.getPageByYear({ year, store, department, name, pageNumber, pageSize })
        const linesList = res.data
        setTimeout( ()=> {
            return dispatch({ type: GET_LINES_DATA_SUCCESS, payload: linesList })
        }, 200)
    } catch (error) {
        dispatch({ type: GET_LINES_DATA_ERROR }) 
        return handleServerError(error)
    }
}

export const resetLinesList = () => async dispatch => {
    console.log(`resetLinesList`)
    return dispatch({ type: RESET_LINES_DATA })
}

export const updateLinesYearAndGetLinesPageByYear = (year, store, department, name, pageNumber, pageSize) => async dispatch => { 
    console.log(`updateLinesYearAndGetLinesPageByYear: ${year}`)
    dispatch({ type: RESET_LINES_DATA })
    dispatch({ type: UPDATE_LINES_YEAR, payload: year })
    dispatch(getLinesPageByYear(year, store, department, name, pageNumber, pageSize))
}

export const updateLinesStoreAndGetLinesPageByYear = (year, store, department, name, pageNumber, pageSize) => async dispatch => { 
    console.log(`updateLinesStoreAndGetLinesPageByYear: ${store}`)
    dispatch({ type: RESET_LINES_DATA })
    dispatch({ type: UPDATE_LINES_STORE, payload: store })
    dispatch(getLinesPageByYear(year, store, department, name, pageNumber, pageSize))
}

export const updateLinesDepartmentAndGetLinesPageByYear = (year, store, department, name, pageNumber, pageSize) => async dispatch => { 
    console.log(`updateLinesDepartmentAndGetLinesPageByYear: ${department}`)
    dispatch({ type: RESET_LINES_DATA })
    dispatch({ type: UPDATE_LINES_DEPARTMENT, payload: department })
    dispatch(getLinesPageByYear(year, store, department, name, pageNumber, pageSize))
}

export const updateLinesNameAndGetLinesPageByYear = (year, store, department, name, pageNumber, pageSize) => async dispatch => { 
    console.log(`updateLinesNameAndGetLinesPageByYear: ${name}`)
    dispatch({ type: RESET_LINES_DATA })
    dispatch({ type: UPDATE_LINES_NAME, payload: name })
    dispatch(getLinesPageByYear(year, store, department, name, pageNumber, pageSize))
}